import React from 'react';
import pedicure from '../../images/beauty/pedicure.png';
import waxing from '../../images/beauty/waxing.png';
import manicure from '../../images/beauty/manicur.png';
import facial from '../../images/beauty/facial.png';
import hairCare from '../../images/beauty/hairCut.png';
import bleach from '../../images/beauty/bleach.png';
import threading from '../../images/beauty/threading.png';
import hairCutMen from '../../images/beauty/hairCutMen.png';
import menPedicure from '../../images/beauty/menPedicure.png';
import menHairColor from '../../images/beauty/menHairColor.png';
import menFacial from '../../images/beauty/menFacial.png';
import menPainRelief from '../../images/beauty/menPainRelief.png';
import menStressRelief from '../../images/beauty/menStressRelief.png';
import postWorkout from '../../images/beauty/postWorkout.png';
import spa from '../../images/spa.png';
import hairSudio from '../../images/hairSudio.png';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
interface UserProps {
  type?: any;
}

const SalonWomen: React.FC<UserProps> = ({ type }) => {
  const { t } = useTranslation();
  const salonWomen = [
    {
      image: pedicure,
      service: 'Pedicure',
    },
    {
      image: waxing,
      service: 'Waxing',
    },
    {
      image: manicure,
      service: 'Menicure',
    },
    {
      image: facial,
      service: 'Facial & Cleanup',
    },
    {
      image: hairCare,
      service: 'Haircare',
    },
    {
      image: bleach,
      service: 'Bleach & Detan',
    },
    {
      image: threading,
      service: 'Threading & Face Waxing',
    },
  ];
  const salonMen = [
    {
      image: hairCutMen,
      service: 'Haircut & Beard  groom shaving',
    },
    {
      image: menPedicure,
      service: 'Pendicure & Menicure',
    },
    {
      image: menHairColor,
      service: 'Hair color & Hair spa',
    },
    {
      image: menFacial,
      service: 'Facial & Cleanup',
    },
  ];
  const massageMen = [
    {
      image: menPainRelief,
      service: 'Pain Relief',
    },
    {
      image: menStressRelief,
      service: 'Stress Relief',
    },
    {
      image: postWorkout,
      service: 'Post Wokout',
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };
  const selectedItem =
    type === 'men' ? salonMen : type === 'massage' ? massageMen : salonWomen;
  return (
    <Grid container className='py-20'>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-10'>
        <Typography variant='h4' className='fw-7'>
          {type === 'men'
            ? t('SALON_MEN')
            : type === 'massage'
            ? t('MASSAGE_MEN')
            : t('SALON_WOMEN')}
        </Typography>
        {type === 'men' && (
          <Typography className='fw-400 f-16 px-10'>
            Grooming essentials
          </Typography>
        )}
        {type === 'massage' && (
          <Typography className='fw-400 f-16 px-10'>
            Curated massages by top therapists.
          </Typography>
        )}
      </Grid>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-10'>
        <Carousel
          responsive={responsive}
          arrows={true}
          showDots={false}
          infinite={false}
          autoPlay={false}
          draggable={false}
        >
          {selectedItem.map((noteData, index) => (
            <div key={index} className='mx-10'>
              <CardContent>
                <Typography variant='subtitle1' className='fw-700'>
                  {noteData.service}
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                height='250'
                image={noteData.image}
                alt={noteData.service}
                className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default SalonWomen;
