import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LookingForList from '../Dashboard/LookingForList';
import GroupsIcon from '@mui/icons-material/Groups';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import saloonPrime from '../../images/beauty/saloonPrime.png';
import spaWomen from '../../images/beauty/spaWomen.png';
import hairSudio from '../../images/beauty/hairSudio.png';
import salonKids from '../../images/beauty/salonKids.png';
import massageMen from '../../images/beauty/massageMen.png';
import beautyMain from '../../images/beauty/beautyMain.png';
import ServicesCarousel from '../Home/ServicesCarousel';
import MostBooked from '../Home/MostBooked';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import rollOnWaing from '../../images/beauty/rollOnWaing.png';
import relaxREcharge from '../../images/beauty/relaxREcharge.png';
import indulge from '../../images/beauty/indulge.png';
import beautyModal from '../../images/beauty/beautyModal.png';
import ayurvedha from '../../images/beauty/stressRelief.png';
import prime from '../../images/beauty/naturalSkin.png';
import ayurvedhaMen from '../../images/beauty/menPainRelief.png';
import primeMen from '../../images/beauty/menStressRelief.png';
import SalonWomen from './SalonWomen';
import CloseIcon from '@mui/icons-material/Close';
import SpaWomen from './SpaWomen';
import HairNailServices from './HairNailServices';
interface ListItem {
  image: string;
  serviceName: string;
  description?: any;
  price: string;
  heading?: string;
}
const BeautyScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listDataOpen, setListDataOpen] = useState(false);

  const [heading, setHeading] = useState('');
  const [listData, setListData] = useState<ListItem[]>([]);
  const listDataonClose = () => {
    setListDataOpen(false);
  };
  const homeList = [
    {
      image: saloonPrime,
      heading: 'Salon Prime',
    },
    {
      image: spaWomen,
      heading: 'Spa for Women',
    },
    {
      image: hairSudio,
      heading: 'Hair Studio for Women',
    },
    {
      image: salonKids,
      heading: 'Salon for Kids & Men',
    },
    {
      image: massageMen,
      heading: 'Massage for men',
    },
  ];
  const womenSpaList: ListItem[] = [
    {
      image: ayurvedha,
      serviceName: 'Ayurvedha',
      description: ['Ancient techniques', 'Ayurvedic'],
      price: '899',
    },
    {
      image: prime,
      serviceName: 'Prime',
      description: ['Expert therapist', 'Essential oils'],
      price: '849',
    },
    {
      image: prime,
      serviceName: 'Luxe',
      description: ['Top therapist', 'Luxury Therapist'],
      price: '1099',
    },
  ];
  const massageMenList: ListItem[] = [
    {
      image: ayurvedhaMen,
      serviceName: 'Ayurvedha',
      description: ['Ancient techniques', 'Ayurvedic'],
      price: '899',
    },
    {
      image: primeMen,
      serviceName: 'Prime',
      description: ['Expert therapist', 'Essential oils'],
      price: '849',
    },
  ];
  const handleBeautyData = (heading: string) => {
    if (heading === 'Salon Prime') {
      navigate('/salonPrime');
    } else if (heading === 'Hair Studio for Women') {
      navigate('/hairStudio');
    } else if (heading === 'Salon for Kids & Men') {
      navigate('/hairStudio');
    } else {
      setListDataOpen(true);
      if (heading === 'Spa for Women') {
        setHeading(heading);
        setListData(womenSpaList);
      } else if (heading === 'Massage for men') {
        setHeading(heading);
        setListData(massageMenList);
      }
    }
  };
  return (
    <Grid container className='mt-30'>
      <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <h1 className='dashboard-header'>{t('BEAUTY_SERVCES')}</h1>
        </Grid>
        <Grid
          item
          container
          xl={10}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          style={{
            border: '2px solid #D3D3D3',
            borderRadius: '10px',
          }}
          className='px-20'
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h1 className='f-20 fw-600 font-os-semi-bold py-10'>
              {t('LOOKING_FOR')}
            </h1>
          </Grid>
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              columnSpacing={3}
              rowSpacing={4}
              className='py-20'
            >
              {homeList?.map((homeData, index) => (
                <Grid item xs={6} xl={4} lg={4} md={4} sm={4}>
                  <Typography
                    onClick={() => handleBeautyData(homeData?.heading)}
                    className='curserEffect listData'
                  >
                    <LookingForList
                      image={homeData?.image}
                      heading={homeData?.heading}
                    />
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xl={10}
          xs={10}
          lg={10}
          md={10}
          sm={10}
          style={{
            display: 'flex',
            justifyContent: 'center',

            marginTop: '40px',
          }}
          container
        >
          <Grid item xl={6}>
            <List>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <StarOutlineIcon
                      sx={{ fontSize: 60, marginRight: '20px' }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Grid container rowSpacing={2}>
                      <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                        <Typography variant='h4' component='h2'>
                          4.8
                        </Typography>
                      </Grid>
                      <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                        <Typography variant='subtitle1'>
                          Service Rating
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xl={6} xs={6} lg={6} md={6} sm={6}>
            <List>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <GroupsIcon sx={{ fontSize: 60, marginRight: '30px' }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Grid container rowSpacing={2}>
                      <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                        <Typography variant='h4' component='h2'>
                          5M+
                        </Typography>
                      </Grid>
                      <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                        <Typography variant='subtitle1' className='gray-color'>
                          Customer Globally
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <img
          src={beautyMain}
          alt='Logo'
          style={{ width: '100%', objectFit: 'contain' }}
          className='py-25'
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-10'>
        <ServicesCarousel type='beauty' />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-10'>
        <MostBooked type='beauty' />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-10'>
        <SalonWomen type='women' />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <img
          src={rollOnWaing}
          alt='img'
          className='lite-gray-border'
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <SpaWomen />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <HairNailServices />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <img
          src={relaxREcharge}
          alt='img'
          className='lite-gray-border'
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <SalonWomen type='men' />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <img
          src={indulge}
          alt='img'
          className='lite-gray-border'
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
        <SalonWomen type='massage' />
      </Grid>
      <Dialog
        open={listDataOpen}
        onClose={listDataonClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        {' '}
        <IconButton
          aria-label='close'
          onClick={listDataonClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h5' component='h2'>
            {heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <img
                src={beautyModal}
                alt='beauty'
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </Grid>
            {listData?.map((beautyData, index) => (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                columnSpacing={2}
                className='pointer-portion'
              >
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <img
                    src={beautyData?.image}
                    alt='beauty'
                    style={{ width: '100%', objectFit: 'contain' }}
                  />
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12} container>
                  <Grid
                    item
                    xl={11}
                    lg={11}
                    md={11}
                    sm={12}
                    xs={12}
                    className='justify-space-between'
                  >
                    <Typography className='f-20 fw-700'>
                      {beautyData?.serviceName}
                    </Typography>
                    <ArrowForwardIosIcon />
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className='f-18 fw-400 py-2'>
                      {beautyData?.description.join(' • ')}
                    </Typography>
                    <Typography className='f-18 py-2'>
                      Starts at
                      <span className='f-18 fw-600 px-5'>
                        {beautyData?.price}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Divider sx={{ bgcolor: '#D3D3D3', mt: 2, mb: 2 }} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default BeautyScreen;
