import React from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  styled,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

interface ReviewData {
  star: number;
  value: number;
  review: string;
}

interface ReviewCardProps {
  rating: any;
  reviewCount: string;
  startValues: ReviewData[];
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  rating,
  reviewCount,
  startValues,
}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? 'black' : 'black',
    },
  }));
  return (
    <Grid container>
      <Grid item xl={0.7} lg={0.7} md={0.7} sm={2} xs={2} className='px-10'>
        <Typography className='fw-600 mt-7'>
          <StarIcon sx={{ fontSize: '20px' }} />
        </Typography>
      </Grid>
      <Grid item xl={11} lg={11} md={11} sm={9} xs={9}>
        <Typography className='fw-600 f-25'>{rating}</Typography>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='px-10'>
        <Typography className='fw-500'>{reviewCount} Reviews</Typography>
      </Grid>
      {startValues.map((reviewData, index) => (
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          className={'px-10'}
          key={index}
        >
          <Grid item xl={0.7} lg={0.7} md={0.7} sm={2} xs={2} className='px-5'>
            <Typography className='fw-600'>
              <StarIcon sx={{ fontSize: '16px' }} />
            </Typography>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
            <Typography className='fw-600 f-16'>{reviewData.star}</Typography>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={4} xs={4}>
            <Box sx={{ width: '100%' }}>
              <BorderLinearProgress
                variant='determinate'
                value={reviewData.value}
                className='mt-10'
              />
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
            <Typography className='fw-400 f-14 px-10'>
              {reviewData.review}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReviewCard;
