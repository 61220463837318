import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoutes from './routes/AuthRoutes';
import AppRoutes from './routes/AppRoutes';
import { CartProvider } from './screens/ProductDescription/CartProvider';

const App = () => {
  return (
    <>
      {' '}
      <CartProvider>
        <Router>
          {/* <AuthRoutes /> */}

          <AppRoutes />
        </Router>
      </CartProvider>
    </>
  );
};

export default App;
