import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import '../Dashboard/dashboard.css';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../routes/constant';
import GroupsIcon from '@mui/icons-material/Groups';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LookingForList from '../Dashboard/LookingForList';
import { useNavigate } from 'react-router-dom';
import appliance from '../../images/home/appliance.png';
import carpenter from '../../images/home/carpenter.png';
import bathroom from '../../images/home/bathroom.png';
import cleaning from '../../images/home/cleaning.png';
import waterPurifier from '../../images/home/waterPurifier.png';
import smartLocks from '../../images/home/smartLocks.png';
import painting from '../../images/home/painting.png';
import airCooler from '../../images/home/airCooler.png';
import airPurifier from '../../images/home/airPurifier.png';
import chimney from '../../images/home/chimney.png';
import geyser from '../../images/home/geyser.png';
import laptop from '../../images/home/laptop.png';
import refridge from '../../images/home/refridge.png';
import television from '../../images/home/tv.png';
import microwave from '../../images/home/microwave.png';
import inverter from '../../images/home/inverter.png';
import mixer from '../../images/home/mixer.png';
import washingMachine from '../../images/home/washingMachine.png';
import wallPanels from '../../images/home/wallPanels.png';
import electrician from '../../images/home/wallPanels.png';
import carpenter1 from '../../images/home/carpenter1.png';
import fullHome from '../../images/home/fullHome.png';
import sofaCarpet from '../../images/home/sofaCarpet.png';
import cockroachAnut from '../../images/home/cockroachAnut.png';
import disinfection from '../../images/home/disinfection.png';
import homeImage from '../../images/home/homeImage.png';
import CloseIcon from '@mui/icons-material/Close';
import squeaky from '../../images/home/squeaky.png';
import rowaterPurifier from '../../images/home/rowaterPurifier.png';

import ServicesCarousel from './ServicesCarousel';
import NoteWorthy from './NoteWorthy';
import MostBooked from './MostBooked';
import AcAppliance from './AcAppliance';
import ElectricianPlumber from './ElectricianPlumber';
interface ListItem {
  image: string;
  heading: string;
}
const HommeScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listDataOpen, setListDataOpen] = useState(false);
  const [heading, setHeading] = useState('');
  const [listData, setListData] = useState<ListItem[]>([]);
  const listDataonClose = () => {
    setListDataOpen(false);
  };
  const homeList = [
    {
      image: appliance,
      heading: 'Appliance Repair & Services',
    },
    {
      image: carpenter,
      heading: 'Electricians & Carpenters',
    },
    {
      image: bathroom,
      heading: 'Bathroom  & Kitchen cleaning',
    },
    {
      image: cleaning,
      heading: 'Cleaning',
    },
    {
      image: waterPurifier,
      heading: 'Native Water Purifier',
    },
    {
      image: smartLocks,
      heading: 'Native Smart Locks',
    },
    {
      image: painting,
      heading: 'Painting & Water proofing',
    },
    {
      image: wallPanels,
      heading: 'Wall panel',
    },
  ];
  const applianceList: ListItem[] = [
    {
      image: airCooler,
      heading: 'Air Cooler & Repair services',
    },
    {
      image: airPurifier,
      heading: 'Air Purifier services',
    },
    {
      image: chimney,
      heading: 'Chimney Repair  & services',
    },
    {
      image: geyser,
      heading: 'Geyser Repair  & services',
    },
    {
      image: laptop,
      heading: 'Laptop Repair  & services',
    },
    {
      image: refridge,
      heading: 'Refrigerator Repair',
    },
    {
      image: washingMachine,
      heading: 'Washing Machine Repair',
    },
    {
      image: microwave,
      heading: 'Microwave Repair',
    },
    {
      image: television,
      heading: 'Television Repair',
    },
    {
      image: waterPurifier,
      heading: 'Water Purifier Repair  & services',
    },
    {
      image: mixer,
      heading: 'Mixer & Grinder Repair',
    },
    {
      image: inverter,
      heading: 'Inverter Repair  & services',
    },
    {
      image: airCooler,
      heading: 'AC Repair  & services',
    },
  ];
  const electricainsList: ListItem[] = [
    {
      image: electrician,
      heading: 'Electrician',
    },
    {
      image: carpenter1,
      heading: 'carpenter',
    },
  ];
  const cleaningList: ListItem[] = [
    {
      image: fullHome,
      heading: 'Full Home Cleaning',
    },
    {
      image: sofaCarpet,
      heading: 'Sofa & Carpet  Cleaning',
    },
    {
      image: cockroachAnut,
      heading: 'Cochroach, ant & genereal pest control',
    },
    {
      image: disinfection,
      heading: 'Disinfection Services',
    },
  ];

  const paintingList: ListItem[] = [
    {
      image: fullHome,
      heading: 'full Home Painting And COnsultation',
    },
    {
      image: wallPanels,
      heading: 'wall Panels',
    },
    {
      image: painting,
      heading: 'Few Rooms /  Wall Painting',
    },
  ];
  const handleHomeData = (heading: string) => {
    if (heading === 'Bathroom  & Kitchen cleaning') {
      navigate('/bathroom');
    } else if (heading === 'Wall panel') {
      navigate('/wallPanel');
    } else if (heading === 'Native Water Purifier') {
      navigate('/nativeWater');
    } else if (heading === 'Native Smart Locks') {
      navigate('/nativeSmartLocks');
    } else if (heading === 'Wall panel') {
      navigate('/wallPanel');
    } else {
      setListDataOpen(true);
      if (heading === 'Appliance Repair & Services') {
        setHeading(heading);
        setListData(applianceList);
      } else if (heading === 'Electricians & Carpenters') {
        setHeading(heading);
        setListData(electricainsList);
      } else if (heading === 'Cleaning') {
        setHeading(heading);
        setListData(cleaningList);
      } else if (heading === 'Painting & Water proofing') {
        setHeading(heading);
        setListData(paintingList);
      }
    }
  };
  const handleHomeList = (heading: string) => {
    if (heading === 'Air Cooler & Repair services') {
      navigate(APP_ROUTES?.AIR_COOLER_REPAIR);
    } else if (heading === 'Air Purifier services') {
      navigate(APP_ROUTES?.AIR_PURIFIER);
    }
  };
  return (
    <Box className='mt-30'>
      <Grid container>
        <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <h1 className='dashboard-header'>{t('HOME_SERVCES')}</h1>
          </Grid>
          <Grid
            item
            container
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
            style={{
              border: '2px solid #D3D3D3',
              borderRadius: '10px',
            }}
            className='px-20'
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className='f-20 fw-600 font-os-semi-bold py-10'>
                {t('LOOKING_FOR')}
              </h1>
            </Grid>
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                columnSpacing={3}
                rowSpacing={4}
                className='py-20'
              >
                {homeList?.map((homeData, index) => (
                  <Grid item xs={6} xl={4} lg={4} md={4} sm={4}>
                    <Typography
                      onClick={() => handleHomeData(homeData?.heading)}
                      className='curserEffect listData'
                    >
                      <LookingForList
                        image={homeData?.image}
                        heading={homeData?.heading}
                      />
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={10}
            xs={10}
            lg={10}
            md={10}
            sm={10}
            style={{
              display: 'flex',
              justifyContent: 'center',

              marginTop: '40px',
            }}
            container
          >
            <Grid item xl={6}>
              <List>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <StarOutlineIcon
                        sx={{ fontSize: 60, marginRight: '20px' }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Grid container rowSpacing={2}>
                        <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                          <Typography variant='h4' component='h2'>
                            4.8
                          </Typography>
                        </Grid>
                        <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                          <Typography variant='subtitle1'>
                            Service Rating
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item xl={6} xs={6} lg={6} md={6} sm={6}>
              <List>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupsIcon sx={{ fontSize: 60, marginRight: '30px' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Grid container rowSpacing={2}>
                        <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                          <Typography variant='h4' component='h2'>
                            5M+
                          </Typography>
                        </Grid>
                        <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                          <Typography
                            variant='subtitle1'
                            className='gray-color'
                          >
                            Customer Globally
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <img
            src={homeImage}
            alt='Logo'
            style={{ width: '100%', objectFit: 'contain' }}
            className='py-25'
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-10'>
          <ServicesCarousel type='home' />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='py-20 px-15'
        >
          <NoteWorthy />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='py-20 px-15'
          container
        >
          <MostBooked />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='py-20 px-15'
          container
        >
          <AcAppliance />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
          <img
            src={squeaky}
            alt='img'
            className='lite-gray-border'
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='py-20 px-15'
          container
        >
          <ElectricianPlumber />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py-20'>
          <img
            src={rowaterPurifier}
            alt='img'
            className='lite-gray-border'
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={listDataOpen}
        onClose={listDataonClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <IconButton
          aria-label='close'
          onClick={listDataonClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h5' component='h2'>
            {heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2}>
            {listData?.map((homeData, index) => (
              <Grid item xs={12} xl={4} lg={4} md={4} sm={6}>
                {' '}
                <Typography
                  onClick={() => handleHomeList(homeData?.heading)}
                  className='curserEffect listData'
                >
                  <LookingForList
                    image={homeData?.image}
                    subHeading={homeData?.heading}
                  />
                </Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default HommeScreen;
