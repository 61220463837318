/* eslint-disable react/require-default-props */
// import Button from "@mui/material/Button";

import { LoadingButton } from '@mui/lab';

interface ButtonInterface {
  text: string | any;
  color?:
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'primary'
    | 'warning'
    | 'inherit'
    | any
    | string;

  endIcon?: any;
  startIcon?: any;
  className?: string | undefined;
  iconClassName?: string | undefined;
  startIconClassName?: string | undefined;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined';
  onClick?: any;
  type?: 'button' | 'submit' | undefined;
  loading?: true | false;
  fontSize?: number | undefined;
  fontWeight?: number | undefined;
  disabled?: boolean | undefined;
  showIcon?: true | false | undefined;
  textAlign?: any;
  width?: any;
  fullWidth?: boolean | any;
}

const CustomButton = ({
  text,
  size,
  onClick,
  variant = 'contained',
  color,
  className,
  startIcon,
  endIcon,
  iconClassName,
  startIconClassName,
  type = 'button',
  loading = false,
  fontSize = 14,
  showIcon = true,
  fontWeight,
  disabled = false,
  textAlign,
  width,
  fullWidth,
}: ButtonInterface) => (
  <LoadingButton
    sx={{
      padding: 0,
      textAlign: { textAlign },
      width: width ? `${width}px` : 'auto',
    }}
    variant={variant}
    size={size}
    disabled={disabled}
    onClick={onClick}
    color={color}
    className={`fw-${fontWeight} f-${fontSize}  ${className}  `}
    type={type}
    loading={loading}
    disableRipple
    fullWidth={fullWidth}
  >
    {text}
  </LoadingButton>
);

export default CustomButton;
