import React from 'react';

import tapRepair from '../../images/home/tapRepair.png';
import flushTankRepair from '../../images/home/flushTankRepair.png';
import jetSpray from '../../images/home/jetSpray.png';
import drillHang from '../../images/home/drillHang.png';
import wastePipe from '../../images/home/wastePipe.png';
import tapReplacement from '../../images/home/tapReplacement.png';
import minorDoorRepair from '../../images/home/minorDoorRepair.png';
import sink from '../../images/home/sink.png';
import sofaCarpetCleaning from '../../images/home/sofaCarpetCleaning.png';
import cupboard from '../../images/home/cupboard.png';

import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';

const ElectricianPlumber: React.FC = () => {
  const { t } = useTranslation();
  const noteWorthyData = [
    {
      image: tapRepair,
      service: 'Tap Repair',
      star: '4.82',
      review: '166.8K',
      price: '139',
    },
    {
      image: flushTankRepair,
      service: 'Flush tank  repair (external PVC)',
      star: '4.79',
      review: '91.8K',
      price: '169',
    },
    {
      image: jetSpray,
      service: 'Jet Spray (installation/repair)',
      star: '4.83',
      review: '96.1K',
      price: '196',
    },
    {
      image: drillHang,
      service: 'Drill & hang (wall decor)',
      star: '4.87',
      review: '125.1K',
      price: '129',
    },
    {
      image: wastePipe,
      service: 'Waste  Pipe',
      star: '4.80',
      review: '82.1K',

      price: '139',
    },
    {
      image: tapReplacement,
      service: 'Tap Replacement',
      star: '4.85',
      review: '52.1K',
      price: '139',
    },
    {
      image: minorDoorRepair,
      service: 'Minor Door Repair',
      star: '4.82',
      review: '52K',
      price: '179',
    },
    {
      image: sink,
      service: 'Sink (drainage , pipe, over the ground)',
      star: '4.81',
      review: '83.9K',
      price: '209',
    },
    {
      image: sofaCarpetCleaning,
      service: 'Door Lock Repair',
      star: '4.85',
      review: '41.7K',
      price: '539',
    },

    {
      image: cupboard,
      service: 'Cupboard hinge service (up to 2)',
      star: '4.84',
      review: '64.7K',
      price: '179',
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <Grid container className='py-20'>
      <Grid
        item
        xl={11}
        xs={12}
        lg={10}
        md={10}
        sm={12}
        className='py-10 justify-space-between'
      >
        <h2 className='fw-600 f-36 font-os-semi-bold'>
          {t('ELECTRICIAN_PLUMBER')}
        </h2>
      </Grid>
      <Grid
        item
        xl={12}
        xs={12}
        lg={12}
        md={12}
        sm={12}
        className='py-10'
        container
      >
        {noteWorthyData.map((noteData, index) => (
          <Grid
            item
            xl={2.2}
            lg={2.5}
            md={3.5}
            sm={5}
            xs={12}
            key={index}
            className='mx-10'
          >
            <CardMedia
              component='img'
              height='250'
              image={noteData.image}
              alt={noteData.service}
              className='carousel-image'
              style={{
                borderRadius: '20px',
                objectFit: 'cover',
              }}
              onClick={() => handleImageClick(index)}
            />
            <CardContent>
              <Grid container className='ml-5' columnGap={1}>
                <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                  <Typography variant='subtitle1' className='fw-700'>
                    {noteData?.service}
                  </Typography>
                </Grid>
                <Grid item xl={1} xs={1} lg={1} md={1} sm={1}>
                  <StarIcon />
                </Grid>
                <Grid item xl={1.5} xs={2} lg={1.5} md={2} sm={2}>
                  {noteData?.star}
                </Grid>
                <Grid item xl={3} xs={3} lg={3} md={3} sm={3}>
                  ({noteData?.review})
                </Grid>
                <Grid
                  item
                  xl={12}
                  xs={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className='ml-5'
                >
                  <Typography gutterBottom variant='subtitle1' component='div'>
                    ₹{noteData?.price}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ElectricianPlumber;
