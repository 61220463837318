import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import '../Dashboard/dashboard.css';
import { APP_ROUTES } from '../../routes/constant';
import { useNavigate } from 'react-router-dom';
interface UserProps {
  image?: any;
  heading?: any;
  subHeading?: any;
}

const LookingForList: React.FC<UserProps> = ({
  image,
  heading,
  subHeading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container rowSpacing={1} className='py-10'>
        <Grid
          item
          xl={12}
          xs={12}
          lg={12}
          md={12}
          sm={12}
          className='list-image'
          style={{
            backgroundColor: 'rgba(245, 245, 245, 1.00)',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10px',
            height: '100px',
          }}
        >
          <img src={image} alt='Logo' />
        </Grid>
        <Grid
          item
          xl={12}
          xs={12}
          lg={12}
          md={12}
          sm={12}
          className='justify-center'
        >
          {heading ? (
            <p className='heading-text f-12 fw-400 font-OpenSans-Regular lh-20 px-10'>
              {heading}
            </p>
          ) : (
            <Typography className='f-12 fw-400'>{subHeading}</Typography>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default LookingForList;
