import * as React from 'react';

import { Button, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import bathroom2 from '../../images/home/bathroom-2.png';
import honeyWell from '../../images/home/honeyWell.png';
import ucBrand from '../../images/home/ucBrand.jpeg';
import keepAirPure from '../../images/home/keepAirPure.png';
import filterCleaning from '../../images/home/filterCleaning.png';
import deepCleaning from '../../images/home/deepCleaning.png';
import '../ProductDescription/product.css';
import ProductList from '../ProductDescription/ProductList';
import ProductDetails from '../ProductDescription/ProductDetails';
import AddtoCart from '../ProductDescription/AddtoCart';
import ImageCarousel from '../../Components/ImageCarousel';
import { useCart } from '../ProductDescription/CartProvider';

interface ServiceDetail {
  serviceAmount?: string;
  packName?: string;
  packAmount?: string;
  time?: string;
  discountPrice?: string;
  image?: string;
  rating?: string;
  booking?: string;
  product?: string;
  description?: string[];
  frequentlyAdded?: any;
  serviceHeading?: any;
  options?: any;
  reviews?: any;
  startValues?: any;
}
interface TotalCount {
  packName: string;
  count: number;
  packAmount: number;
  discountPrice: number;
}
interface GroupedService {
  [key: string]: ServiceDetail[];
}
export interface Service {
  name: string;
  cost: number;
}

interface UserProps {
  image?: any;
  heading?: any;
  subHeading?: any;
}

const AirPurifier: React.FC<UserProps> = ({ image, heading, subHeading }) => {
  const { t } = useTranslation();
  const { totalCart, setTotalCart } = useCart();
  const mainCarouselImages = [honeyWell, keepAirPure, ucBrand];
  const bathRoomProductData1: ServiceDetail[] = [
    {
      serviceHeading: 'Service',
      packName: 'Filter Cleaning Service',
      packAmount: '399',
      time: '30 mins',
      image: filterCleaning,
      rating: '4.89',
      booking: '1K',
      description: [
        ' Includes full cleaning of all filters',
        'Recommended if your filter is less than 12 months old',
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Priyanka',
          serviceName: 'No power, deep cleaning',
          review:
            'Minhal is professional and did the work nicely. Would recommend for sure',
          star: '5',
          date: '5-05-2024',
        },
        {
          heading: 'in my area',
          name: 'Solomon',
          serviceName: 'For Repair - No Power',
          review:
            'Sarfaraz was excellent and prompt with his work and professionalism.',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Abhay',
          serviceName: 'For Repair - Noise Issue',
          review:
            'Had requested a repair because my air purifier was making noise. The person suggested servicing the purifier which would fix the noise issue as well. ',
          star: '3.4',
          date: '5-2-2023',
        },
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
    },
    {
      serviceHeading: 'Service',
      packName: 'Deep Cleaning Service',
      packAmount: '399',
      time: '30 mins',
      image: deepCleaning,
      rating: '4.89',
      booking: '1K',
      description: [
        'Complete dismantling of all parts (motor/ fan, casing and filters) for thorough deep clean',
        'Recommended if your filter is more than 12 months old',
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Priyanka',
          serviceName: 'No power, deep cleaning',
          review:
            'Minhal is professional and did the work nicely. Would recommend for sure',
          star: '5',
          date: '5-05-2024',
        },
        {
          heading: 'in my area',
          name: 'Solomon',
          serviceName: 'For Repair - No Power',
          review:
            'Sarfaraz was excellent and prompt with his work and professionalism.',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Abhay',
          serviceName: 'For Repair - Noise Issue',
          review:
            'Had requested a repair because my air purifier was making noise. The person suggested servicing the purifier which would fix the noise issue as well. ',
          star: '3.4',
          date: '5-2-2023',
        },
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
    },

    {
      serviceHeading: 'Repair',
      packName: 'Air Purifier Repair',
      packAmount: '299',
      time: '30 mins',
      image: filterCleaning,
      rating: '4.83',
      booking: '611 reviews',
      description: [
        'Price incluedes visits and diagnosis',
        'Spare parts rates applicable as per rate card',
      ],
      options: [
        {
          serviceHeading: 'Repair',
          packName: 'Repair - No Power',
          packAmount: '299',
          time: '45 mins',
          image: deepCleaning,
          rating: '4.84',
          booking: '463',
        },
        {
          serviceHeading: 'Repair',
          packName: 'Repair - No Power',
          packAmount: '299',
          time: '45 mins',
          image: deepCleaning,
          rating: '4.84',
          booking: '463',
        },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Priyanka',
          serviceName: 'No power, deep cleaning',
          review:
            'Minhal is professional and did the work nicely. Would recommend for sure',
          star: '5',
          date: '5-05-2024',
        },
        {
          heading: 'in my area',
          name: 'Solomon',
          serviceName: 'For Repair - No Power',
          review:
            'Sarfaraz was excellent and prompt with his work and professionalism.',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Abhay',
          serviceName: 'For Repair - Noise Issue',
          review:
            'Had requested a repair because my air purifier was making noise. The person suggested servicing the purifier which would fix the noise issue as well. ',
          star: '3.4',
          date: '5-2-2023',
        },
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
    },
  ];

  const groupByServiceHeading = (data: ServiceDetail[]): GroupedService => {
    return data.reduce((acc: GroupedService, item: ServiceDetail) => {
      if (!acc[item.serviceHeading]) {
        acc[item.serviceHeading] = [];
      }
      acc[item.serviceHeading].push(item);
      return acc;
    }, {});
  };
  const groupedData = groupByServiceHeading(bathRoomProductData1);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const handleViewService = () => {
    window.location.hash = `#service`;
  };
  return (
    <Grid container className='mt-30'>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={12}
        xs={12}
        className='px-10 fixed-section'
        container
      >
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <ProductList
            heading={t('AIR_PURIFIER_SERVICE')}
            rating={'4.71'}
            booking={'131K'}
            serviceButton={true}
            handleViewService={handleViewService}
          />
        </Grid>
      </Grid>
      <Grid item xl={9} lg={9} md={9} sm={12} xs={12} container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sm={12}
          container
          columnSpacing={2}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='scrollable-section'
          >
            <ImageCarousel
              images={mainCarouselImages}
              infinite={true}
              autoPlay={true}
              showDots={true}
              responsive={responsive}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            id={`service`}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className='px-5'
            >
              {Object.entries(groupedData).map(([heading, services]) => (
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  xl={12}
                  key={heading}
                >
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <h2 className='f-28 fw-700 font-os-semi-bold'>{heading}</h2>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    {services.map((product: ServiceDetail, index: number) => (
                      <ProductDetails
                        key={index}
                        productData={product}
                        serviceHeading={heading}
                        totalCart={totalCart}
                        setTotalCart={setTotalCart}
                        index={index}
                        heading={heading}
                        imageHeight={'airPurifierImage'}
                        addHeight={'airPurifierImageAdd'}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            xl={5}
            lg={5}
            className='fixed-section'
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              container
              className='mt-30'
            >
              <AddtoCart />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AirPurifier;
