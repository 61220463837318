import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Grid, IconButton, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import './dashboard.css';
import CloseIcon from '@mui/icons-material/Close';
interface UserProps {
  otpOpen?: any;
  handleOtpClose?: any;
  mobileNumber?: any;
  setLoginOpen?: any;
  setOtpOpen?: any;
  setEditNumber?: any;
}

interface FormData {
  otp: string;
}

const OtpModal: React.FC<UserProps> = ({
  otpOpen,
  handleOtpClose,
  mobileNumber,
  setLoginOpen,
  setOtpOpen,
  setEditNumber,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const { t } = useTranslation();

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = React.useState(30);
  console.log('pppppp', mobileNumber);
  const onSubmit = (data: any) => {
    // handle form submission
  };

  const handleResend = () => {
    setOtp('');
    setTimer(30);
    setIsButtonDisabled(true);
    // handle resend logic here
  };
  const handleEditNumber = () => {
    setOtpOpen(false);
    setLoginOpen(true);
    setEditNumber(true);
  };
  React.useEffect(() => {
    if (otp.length === 4) {
      setIsButtonDisabled(false);
    }
  }, [otp]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (otpOpen) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    } else {
      setTimer(30);
    }

    return () => clearInterval(interval);
  }, [otpOpen]);

  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };
  React.useEffect(() => {
    if (!otpOpen) {
      setTimer(30);
    }
  }, [otpOpen]);
  return (
    <React.Fragment>
      <Dialog
        open={otpOpen}
        onClose={handleOtpClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        {' '}
        <IconButton
          aria-label='close'
          onClick={handleOtpClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          {' '}
          <Typography
            variant='h5'
            component='h2'
            className='edit-number'
            onClick={handleEditNumber}
          >
            {t('EDIT_NUMBER')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={12}>
                <Typography variant='h6' component='h2'>
                  {t('VERIFICATION_CODE')}
                </Typography>
                <Typography variant='subtitle2'>
                  {t('4_DIGIT_CODE')}
                  {''}
                  {mobileNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name='otp'
                  control={control}
                  rules={{
                    required: t('OTP_VALIDATION_1'),
                    pattern: {
                      value: /^\d{6}$/,
                      message: 'OTP must be exactly 4 digits',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{
                            width: '3rem',
                            height: '3rem',
                            margin: '0 0.5rem',
                            fontSize: '1.5rem',
                            borderRadius: '4px',
                            border: '1px solid black',
                            textAlign: 'center',
                          }}
                        />
                      )}
                    />
                  )}
                />
                {errors.otp && (
                  <Typography color='error' align='center'>
                    {errors.otp.message}
                  </Typography>
                )}
                {timer > 0 ? (
                  <Typography variant='subtitle2' className='resend-text'>
                    {formatTimer(timer)}
                  </Typography>
                ) : (
                  <Button onClick={handleResend}>
                    <Typography variant='subtitle2'>Resend Code</Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: '10px' }}>
          <Button
            variant='contained'
            size='large'
            fullWidth
            onClick={handleOtpClose}
            disabled={isButtonDisabled}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default OtpModal;
