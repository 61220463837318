import React from 'react';
import acRepairService from '../../images/home/acRepairService.png';
import tvRepair from '../../images/home/tvRepair.png';
import purifierRepair from '../../images/home/purifierRepair.png';
import chimneyRepair from '../../images/home/chimneyRepair.png';
import fridgeRepair from '../../images/home/fridgeRepair.png';
import wasingMachineRepair from '../../images/home/wasingMachineRepair.png';
import geyserRepair from '../../images/home/geyserRepair.png';
import microwaveRepair from '../../images/home/microwaveRepair.png';
import fullHomeCLeaning from '../../images/home/fullHomeCLeaning.png';
import sofaCarpetCleaning from '../../images/home/sofaCarpetCleaning.png';
import generalPest from '../../images/home/generalPest.png';
import disinfectionServices from '../../images/home/disinfectionServices.png';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

const AcAppliance: React.FC = () => {
  const { t } = useTranslation();
  const salonWomen = [
    {
      image: acRepairService,
      service: 'AC Repair & Service',
    },
    {
      image: wasingMachineRepair,
      service: 'Wasing Machine Repair',
    },
    {
      image: purifierRepair,
      service: 'Water Purifier Repair & Service',
    },
    {
      image: chimneyRepair,
      service: 'Chimney Repair & Service',
    },
    {
      image: tvRepair,
      service: 'Television Repair',
    },
    {
      image: fridgeRepair,
      service: 'Refridgrator Repair',
    },
    {
      image: geyserRepair,
      service: 'Geyser Repaiir & Service',
    },
    {
      image: microwaveRepair,
      service: 'Microwave Repaiir ',
    },
  ];
  const cleanPest = [
    {
      image: fullHomeCLeaning,
      service: 'Full Home Cleaning',
    },
    {
      image: sofaCarpetCleaning,
      service: 'Sofa and Carpet Cleaning',
    },
    {
      image: generalPest,
      service: 'Cockroach, ant & Gemeral pest control',
    },
    {
      image: disinfectionServices,
      service: 'Desinfection Services',
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <Grid container className='py-20'>
      <Grid
        item
        xl={11}
        xs={12}
        lg={10}
        md={10}
        sm={12}
        className='py-10 justify-space-between'
      >
        <h2 className='fw-600 f-36 font-os-semi-bold'>{t('AC_APPLIANICE')}</h2>
      </Grid>
      <Grid
        item
        xl={1}
        xs={12}
        lg={2}
        md={2}
        sm={12}
        className='py-10 justify-end'
      >
        <Button>See All</Button>
      </Grid>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-20'>
        <Carousel
          responsive={responsive}
          arrows={true}
          showDots={false}
          infinite={false}
          autoPlay={false}
          draggable={false}
        >
          {salonWomen.map((noteData, index) => (
            <div key={index} className='mx-10 lite-gray-border'>
              <CardContent>
                <Typography className='fw-600 f-16 font-os-semi-bold'>
                  {noteData.service}
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                height='250'
                image={noteData.image}
                alt={noteData.service}
                // className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
        </Carousel>
      </Grid>{' '}
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-20'>
        <Typography className='fw-600 f-36 font-os-semi-bold'>
          {t('CLEAN_PEST')}
        </Typography>
      </Grid>
      <Grid
        item
        xl={12}
        xs={12}
        lg={12}
        md={12}
        sm={12}
        className='py-10'
        container
        rowGap={1}
      >
        {cleanPest.map((noteData, index) => (
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={3}
            xs={12}
            key={index}
            className='mx-10 lite-gray-border'
          >
            <CardContent>
              <Typography className='fw-600 f-16 font-os-semi-bold'>
                {noteData.service}
              </Typography>
            </CardContent>
            <CardMedia
              component='img'
              image={noteData.image}
              alt={noteData.service}
              // className='carousel-image'
              style={{
                borderRadius: '20px',
                height: 'auto',
              }}
              onClick={() => handleImageClick(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AcAppliance;
