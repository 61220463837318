import React from 'react';

import fullHomeCLeaning from '../../images/home/fullHomeCLeaning.png';
import stressRelief from '../../images/beauty/stressRelief.png';
import painRelief from '../../images/beauty/painRelief.png';
import naturalSkin from '../../images/beauty/naturalSkin.png';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

const SpaWomen: React.FC = () => {
  const { t } = useTranslation();
  const SpaWomenData = [
    {
      image: stressRelief,
      service: 'Stress Relief',
    },
    {
      image: painRelief,
      service: 'Pain Relief',
    },
    {
      image: naturalSkin,
      service: 'Natural Skin',
    },
  ];
  const cleanPest = [
    {
      image: fullHomeCLeaning,
      service: 'Full Home Cleaning',
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <Grid container className='py-20'>
      <Grid item xl={11} xs={12} lg={10} md={10} sm={12} className='py-10'>
        <Typography className='fw-600 f-36 font-os-semi-bold'>
          {t('SPA_FOR_WOMEN')}
        </Typography>
        <Typography className='fw-400 f-16 px-10'>
          Refresh. Rewind. Rejuvenate.
        </Typography>
      </Grid>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-20'>
        <Carousel
          responsive={responsive}
          arrows={true}
          showDots={false}
          infinite={false}
          autoPlay={false}
          draggable={false}
        >
          {SpaWomenData.map((noteData, index) => (
            <div key={index} className='mx-10 lite-gray-border'>
              <CardContent>
                <Typography className='fw-600 f-16 font-os-semi-bold'>
                  {noteData.service}
                </Typography>
              </CardContent>
              <CardMedia
                component='img'
                height='250'
                image={noteData.image}
                alt={noteData.service}
                // className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
        </Carousel>
      </Grid>{' '}
    </Grid>
  );
};

export default SpaWomen;
