import * as React from 'react';

import { Button, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import exhaustFan from '../../images/home/exhausFan.png';
import noPower from '../../images/home/noPower.png';
import fanSlow from '../../images/home/fanSlow.png';
import bathroom2 from '../../images/home/bathroom-2.png';
import sidePad from '../../images/home/sidePad.png';
import viewSidePAd from '../../images/home/viewSidePAd.png';
import airCoolerCheckup from '../../images/home/airCoolerCheckup.png';
import '../ProductDescription/product.css';
import ProductList from '../ProductDescription/ProductList';
import ProductDetails from '../ProductDescription/ProductDetails';
import AddtoCart from '../ProductDescription/AddtoCart';
import { useCart } from '../ProductDescription/CartProvider';
import { APP_ROUTES } from '../../routes/constant';
import { Link } from 'react-router-dom';

interface ServiceDetail {
  serviceAmount?: string;
  packName?: string;
  packAmount?: string;
  time?: string;
  discountPrice?: string;
  image?: string;
  rating?: string;
  booking?: string;
  product?: string;
  description?: string[];
  frequentlyAdded?: any;
  serviceHeading?: any;
  options?: any;
  reviews?: any;
  startValues?: any;
}
interface TotalCount {
  packName: string;
  count: number;
  packAmount: number;
  discountPrice: number;
}
interface GroupedService {
  [key: string]: ServiceDetail[];
}

interface UserProps {
  image?: any;
  heading?: any;
  subHeading?: any;
}

const AirCoolerRepairServices: React.FC<UserProps> = ({
  image,
  heading,
  subHeading,
}) => {
  const { t } = useTranslation();
  // const [totalCart, setTotalCart] = React.useState<TotalCount[]>([]);

  const productData: ServiceDetail[] = [
    {
      serviceHeading: 'Servicing',
      packName: 'Air Cooler servicing  & side pad Replacement',
      packAmount: '599',
      time: '2 hrs',
      image: sidePad,
      rating: '4.73',
      booking: '10K',
      product: viewSidePAd,

      description: [
        'Leads to faster Cooling.',
        'Cost of pad will be additional   as per th rate card',
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Priyanka',
          serviceName: 'No power, deep cleaning',
          review:
            'Minhal is professional and did the work nicely. Would recommend for sure',
          star: '5',
          date: '5-05-2024',
        },
        {
          heading: 'in my area',
          name: 'Solomon',
          serviceName: 'For Repair - No Power',
          review:
            'Sarfaraz was excellent and prompt with his work and professionalism.',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Abhay',
          serviceName: 'For Repair - Noise Issue',
          review:
            'Had requested a repair because my air purifier was making noise. The person suggested servicing the purifier which would fix the noise issue as well. ',
          star: '3.4',
          date: '5-2-2023',
        },
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
    },
    {
      serviceHeading: 'Check Up/ Repair',
      packName: 'Air Cooler Checkup & Repair',
      packAmount: '599',
      time: '2 hrs',
      image: airCoolerCheckup,
      rating: '4.73',
      booking: '10K',
      product: airCoolerCheckup,
      frequentlyAdded: [
        {
          image: exhaustFan,
          name: 'Exhaust Fan for kitchen',
          amount: '82',
        },
        {
          image: exhaustFan,
          name: 'Exhaust Fan for kitchen',
          amount: '82',
        },
      ],
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      options: [
        {
          serviceHeading: 'Check Up/ Repair',
          image: noPower,
          packName: 'No Power',
          packAmount: '249',
          time: '60 mins',
          rating: '4.68',
          booking: '3K',
          description: [
            'Price includes visit and diagnosis charge',
            'Spare part/Labour Rates applcable as per rate card',
          ],
        },
        {
          serviceHeading: 'Check Up/ Repair',
          image: fanSlow,
          packName: 'Fan slow',
          packAmount: '249',
          time: '60 mins',
          rating: '4.68',
          booking: '3K',
          description: [
            'Price includes visit and diagnosis charge',
            'Spare part/Labour Rates applcable as per rate card',
          ],
        },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Priyanka',
          serviceName: 'No power, deep cleaning',
          review:
            'Minhal is professional and did the work nicely. Would recommend for sure',
          star: '5',
          date: '5-05-2024',
        },
        {
          heading: 'in my area',
          name: 'Solomon',
          serviceName: 'For Repair - No Power',
          review:
            'Sarfaraz was excellent and prompt with his work and professionalism.',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Abhay',
          serviceName: 'For Repair - Noise Issue',
          review:
            'Had requested a repair because my air purifier was making noise. The person suggested servicing the purifier which would fix the noise issue as well. ',
          star: '3.4',
          date: '5-2-2023',
        },
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
    },
  ];
  const frequentlyAskedQuestion = [
    {
      question: 'Wiil i have to provide any chemicals or cleaning agent?',
      answer:
        'No, our professionals carry all chemicals and equipment required for the service.',
    },
    {
      question: ' Which chemicals will be used during bathroom cleaning?',
      answer:
        ' The two main chemicals used are SwipePro Bathroom cleaner and Diversey Suma Multi.',
    },
    {
      question:
        'What will happen if theres no electricity at my home during the service?',
      answer:
        'In such cases, customers can get the service rescheduled with the help of Urban Companys customer care.',
    },
    {
      question: 'Will the professionals follow all safety measures? ',
      answer:
        'Yes. Our professionals will take all the necessary measures like social distancing, wearing a mask, etc.',
    },
    {
      question: 'How frequently should I book this service?',
      answer:
        'You should ideally book this service once every month for a clean and hygienic bathroom.',
    },
    {
      question:
        'My bathroom & toilet are in two separate areas, will both be covered?',
      answer: 'Yes, both areas will be cleaned',
    },
  ];
  const reviews = [
    {
      heading: 'frequent',
      name: 'Vijay Basavarajappa',
      serviceName: 'For Classic Bathroom Cleaning',
      review:
        'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
      star: '5',
      date: '5-08-2024',
    },
    {
      heading: 'in my area',
      name: 'Marlene Peacock',
      serviceName:
        'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
      review:
        'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
      star: '3.4',
      date: '15-07-2023',
    },
    {
      heading: 'most detailed',
      name: 'Karthik',
      serviceName: 'For Classic Bathroom Cleaning',
      review:
        'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
      star: '3.4',
      date: '5-2-2024',
    },
  ];
  const startValues = [
    { star: '1', value: '2', review: '550' },
    { star: '2', value: '5', review: '850' },
    { star: '3', value: '7', review: '650' },
    { star: '4', value: '50', review: '50K' },
    { star: '5', value: '90', review: '1.1 M' },
  ];
  const groupByServiceHeading = (data: ServiceDetail[]): GroupedService => {
    return data.reduce((acc: GroupedService, item: ServiceDetail) => {
      if (!acc[item.serviceHeading]) {
        acc[item.serviceHeading] = [];
      }
      acc[item.serviceHeading].push(item);
      return acc;
    }, {});
  };
  const groupedData = groupByServiceHeading(productData);
  console.log('groupedData', groupedData);
  const { totalCart, setTotalCart } = useCart();
  const handleViewService = () => {
    window.location.hash = `#service`;
  };
  return (
    <Grid container>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        container
        columnSpacing={1}
        className='mt-30'
      >
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={4}
          xs={12}
          className='px-10 fixed-section'
          container
        >
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <ProductList
              heading={t('AIR_COOLER_REPAIR_SERVICE')}
              rating={'4.71'}
              booking={'131K'}
              serviceButton={true}
              handleViewService={handleViewService}
            />
          </Grid>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={8} xs={12} container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            columnSpacing={2}
            id={`service`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              container
              className='mt-30'
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                container
                className='px-5 gray-border mt-50'
              >
                {Object.entries(groupedData).map(([heading, services]) => (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    xl={12}
                    key={heading}
                    className='px-20'
                  >
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <h2 className='f-28 fw-700 font-os-semi-bold'>
                        {heading}
                      </h2>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <Link to={APP_ROUTES?.RATE_CARD}>
                        <p className='blue-text'>Spare part prices</p>
                      </Link>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      {services.map((product: ServiceDetail, index: number) => (
                        <ProductDetails
                          key={index}
                          productData={product}
                          serviceHeading={heading}
                          totalCart={totalCart}
                          setTotalCart={setTotalCart}
                          index={index}
                          frequentlyAskedQuestion={frequentlyAskedQuestion}
                          heading={t('AIR_COOLER_REPAIR_SERVICE')}
                          imageHeight={'acImage'}
                          addHeight={'acImageAdd'}
                        />
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={5}
              lg={5}
              className='fixed-section'
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                container
                className='mt-50'
              >
                <AddtoCart />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AirCoolerRepairServices;
