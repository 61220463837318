import React from 'react';
import { Grid, Typography } from '@mui/material';

interface CartButtonProps {
  productData: {
    id: string; // Change this to string
    packName: string;
    options?: string[];
  };
  index: number;
  counts: Record<string, { count: number }>;
  serviceHeading: string;
  imageHeight?: any;
  handleAddClick: (id: string, packName: string, index: number) => void; // Change this to string
  handleRemoveClick: (id: string, packName: string, index: number) => void; // Change this to string
}

const CartButton: React.FC<CartButtonProps> = ({
  productData,
  index,
  counts,
  serviceHeading,
  handleAddClick,
  handleRemoveClick,
  imageHeight,
}) => {
  return (
    <Grid
      container
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      justifyContent='center'
    >
      <Grid
        item
        xl={8}
        lg={8}
        md={8}
        sm={8}
        xs={12}
        container
        className={`image-button px-10 py-5 ${imageHeight || ''}`}
        style={{
          backgroundColor: 'white',
          border: '2px solid rgb(110, 66, 229)',
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xl={4}>
            <Typography
              onClick={() =>
                handleRemoveClick(productData.id, productData.packName, index)
              }
              className='blue-button px-2 fw-700 f-14'
            >
              -
            </Typography>
          </Grid>
          <Grid item xl={4}>
            <Typography className='blue-button px-2 fw-700 f-14'>
              {counts[`${serviceHeading}-${index}`]?.count || 0}
            </Typography>
          </Grid>
          <Grid item xl={4}>
            <Typography
              onClick={() =>
                handleAddClick(productData.id, productData.packName, index)
              }
              className='blue-button px-2 fw-700 f-14'
            >
              +
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {productData?.options && (
          <Typography textAlign='center' className='f-14 fw-400 gray-font'>
            {productData?.options?.length} Options
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CartButton;
