import React from 'react';
import nativeWaterPurifier from '../../images/home/nativeWaterPurifier.png';
import bathroomKitchenCleaning from '../../images/home/bathroomKitchenCleaning.png';
import fullHomePainting from '../../images/home/fullHomePainting.png';
import nativeSmartLocks from '../../images/home/nativeSmartLocks.png';
import acRepairService from '../../images/home/acRepairService.png';
import spa from '../../images/beauty/spa.png';
import hairSudio from '../../images/beauty/hairSudio.png';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

const NoteWorthy: React.FC = () => {
  const { t } = useTranslation();
  const noteWorthyData = [
    {
      image: nativeWaterPurifier,
      service: 'Native Water Purifier',
    },
    {
      image: bathroomKitchenCleaning,
      service: 'Bathroom & Kitchen Cleaning',
    },
    {
      image: fullHomePainting,
      service: 'Full Home Painting',
    },
    {
      image: nativeSmartLocks,
      service: 'Native Smart Locks',
    },
    // {
    //   image: spa,
    //   service: 'Spa Ayurvedha',
    // },
    // {
    //   image: hairSudio,
    //   service: 'Hair Studio for Women',
    // },
    {
      image: acRepairService,
      service: 'AC Repair and Services',
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <Grid container className='py-20'>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-10'>
        <h2 className='fw-600 f-36 font-os-semi-bold'>{t('NEW_NETWORTHY')}</h2>
      </Grid>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-10'>
        <Carousel
          responsive={responsive}
          arrows={true}
          showDots={false}
          infinite={false}
          autoPlay={false}
          draggable={false}
        >
          {noteWorthyData.map((noteData, index) => (
            <div key={index} className='mx-10'>
              <CardMedia
                component='img'
                height='250'
                image={noteData.image}
                alt={noteData.service}
                className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
              <CardContent>
                <Typography className='fw-600 f-16'>
                  {noteData.service}
                </Typography>
              </CardContent>
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default NoteWorthy;
