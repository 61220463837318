import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import acIncluded from '../../images/home/acIncluded.png';
import acExcluded from '../../images/home/acExcluded.png';
import acHowWorks from '../../images/home/acHowWorks.png';
import bathroom1 from '../../images/home/cleaning-1.png';
import bathroom2 from '../../images/home/cleaning-2.png';
import included1 from '../../images/home/included1 (1).png';
import included5 from '../../images/home/shine.png';
import excluded from '../../images/home/excluded.png';
import spotless from '../../images/home/spotless.png';
import cleaning from '../../images/home/cleaning (2).png';
import './product.css';
import ReviewFilter from './ReviewFilter';
import ImageCarousel from '../../Components/ImageCarousel';
import ReviewCard from './ReviewCard';
interface UserProps {
  viewDetailsOpen?: any;
  viewData?: any;
  viewDetailsClose?: any;
  setCounts?: any;
  counts?: any;
  index?: any;
  handleRemoveClick?: any;
  handleAddClick?: any;
  frequentlyAskedQuestion?: any;
  heading?: any;
  updateTotalCounts?: any;
  mainCarouselImages?: any;
}

const ViewDetailsBathRoom: React.FC<UserProps> = ({
  viewDetailsOpen,
  viewData,
  viewDetailsClose,
  setCounts,
  counts,
  index,
  handleRemoveClick,
  handleAddClick,
  frequentlyAskedQuestion,
  mainCarouselImages,
  heading,
  updateTotalCounts,
}) => {
  const { t } = useTranslation();

  const items = [bathroom1, bathroom2];

  const [ratingFilters, setRatingFilters] = React.useState<number[]>([]);
  const [sortByFilter, setSortByFilter] = React.useState<string | null>(null);
  const [otherFilters, setOtherFilters] = React.useState<number[]>([]);
  const handleApplyFilters = (filters: {
    rating: number[];
    sortBy: string | null;
    others: number[];
  }) => {
    setRatingFilters(filters.rating);
    setSortByFilter(filters.sortBy);
    setOtherFilters(filters.others);

    const newFilteredReviews = viewData?.reviews.filter((review?: any) => {
      const ratingMatch =
        filters.rating.length === 0 ||
        filters.rating.includes(parseInt(review.star));
      const sortMatch =
        !filters.sortBy || review.heading.includes(filters.sortBy);
      const othersMatch =
        filters.others.length === 0 ||
        filters.others.some((other) =>
          review.heading.includes(other.toString())
        );

      return ratingMatch && sortMatch && othersMatch;
    });
    console.log('newFilteredReviews', newFilteredReviews);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  console.log('viewData', viewData);

  return (
    <Dialog
      open={viewDetailsOpen}
      onClose={viewDetailsClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '10px',
        },
      }}
    >
      <IconButton
        aria-label='close'
        onClick={viewDetailsClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowGap={1} className='py-10'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {viewData?.product ? (
              <img src={viewData?.product} />
            ) : mainCarouselImages ? (
              <ImageCarousel
                images={items}
                infinite={true}
                autoPlay={true}
                showDots={true}
                responsive={responsive}
              />
            ) : (
              <></>
            )}
          </Grid>

          {viewData?.options ? (
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              className='py-5'
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant='h6' className='fw-600 f-20'>
                  {viewData?.packName}
                </Typography>
              </Grid>
              <Grid item xl={0.5} lg={0.5} md={0.5} sm={1} xs={1}>
                <StarOutlineIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Typography className='f-12 fw-400'>
                  {viewData?.rating} ({viewData?.booking})
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                className='justify-space-between py-5'
              >
                <Grid item xl={6} lg={6} md={6} xs={12} sm={6}>
                  <Typography variant='h6' className='fw-600 f-20'>
                    {viewData?.packName}
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12} sm={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {counts[`${viewData?.serviceHeading}-${index}`] ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          variant='outlined'
                          size='small'
                          style={{ backgroundColor: 'white' }}
                        >
                          <Button
                            onClick={() =>
                              handleRemoveClick(
                                viewData.id,
                                viewData.packName,
                                index
                              )
                            }
                          >
                            -
                          </Button>
                          <Typography sx={{ mx: 2 }}>
                            {counts[`${viewData?.serviceHeading}-${index}`]
                              ?.count || 0}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleAddClick(
                                viewData.id,
                                viewData.packName,
                                index
                              )
                            }
                          >
                            +
                          </Button>
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        variant='outlined'
                        onClick={() =>
                          handleAddClick(viewData.id, viewData.packName, index)
                        }
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={0.5} lg={0.5} md={0.5} sm={1} xs={1}>
                <StarOutlineIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Typography className='f-12 fw-400'>
                  {viewData?.rating} ({viewData?.booking})
                </Typography>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1.5} xs={1.5}>
                <Typography className='fw-700 f-14'>
                  ₹{viewData?.packAmount}
                </Typography>
              </Grid>
              {viewData?.discountPrice && (
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                  <Typography className='font-line-through fw-700 f-14'>
                    ₹{viewData?.discountPrice}
                  </Typography>
                </Grid>
              )}
              <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
                <Typography className='service-amount fw-700 f-14'>
                  {viewData?.time}
                </Typography>
              </Grid>
              {viewData?.discountPrice && (
                <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                  <Typography className='service-amount'>
                    ₹{viewData?.discountPrice - viewData?.packAmount} off
                    applied
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>

        {viewData?.options && (
          <Grid container>
            {viewData?.options?.map((viewOptions?: any, index?: any) => (
              <Grid
                key={index}
                id={index}
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                className='justify-space-between py-5'
              >
                <Grid item xl={8} lg={8} md={8} xs={12} sm={12} container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant='h6' className='fw-600 f-20'>
                      {viewOptions?.packName}
                    </Typography>
                  </Grid>
                  <Grid item xl={0.7} lg={0.7} md={0.5} sm={1} xs={1}>
                    <StarOutlineIcon sx={{ fontSize: '18px' }} />
                  </Grid>
                  <Grid item xl={10.4} lg={10.3} md={11} sm={11} xs={11}>
                    <Typography className='f-12 fw-400'>
                      {viewOptions?.rating} ({viewOptions?.booking})
                    </Typography>
                  </Grid>
                  <Grid item xl={1.5} lg={1.5} md={1} sm={1.5} xs={1.5}>
                    <Typography className='fw-700 f-14'>
                      ₹{viewOptions?.packAmount}
                    </Typography>
                  </Grid>
                  {viewOptions?.discountPrice && (
                    <Grid item xl={1.5} lg={1.5} md={1} sm={2} xs={2}>
                      <Typography className='font-line-through fw-700 f-14'>
                        ₹{viewOptions?.discountPrice}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
                    <Typography className='service-amount fw-700 f-14'>
                      {viewOptions?.time}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <List>
                      {viewOptions?.description?.map(
                        (desc: string, descIndex: number) => (
                          <ListItem
                            key={descIndex}
                            className='list-item-with-dot '
                          >
                            <ListItemText
                              primary={desc}
                              className='f-12 fw-400 font-OpenSans-Regular'
                            />
                          </ListItem>
                        )
                      )}
                    </List>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={8}
                  lg={4}
                  md={4}
                  sm={4}
                  xl={4}
                  className='image-container'
                >
                  <img
                    src={viewOptions?.image}
                    alt='Logo'
                    className='product-image'
                    style={{ width: '150px', height: '150px' }}
                  />
                  {counts[`${viewOptions?.serviceHeading}-${index}`] ? (
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Button
                          variant='outlined'
                          className='image-button'
                          size='small'
                          style={{
                            backgroundColor: 'white',
                            border: '2px solid rgb(110, 66, 229)',
                          }}
                        >
                          <Button
                            size='small'
                            onClick={() =>
                              handleRemoveClick(
                                viewOptions.id,
                                viewOptions.packName,
                                index,
                                viewOptions?.serviceHeading,
                                viewOptions?.packAmount
                              )
                            }
                          >
                            -
                          </Button>
                          <Typography sx={{ mx: 2 }}>
                            {counts[`${viewOptions?.serviceHeading}-${index}`]
                              ?.count || 0}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        // justifyContent={'center'}
                      >
                        <Button
                          variant='outlined'
                          style={{
                            backgroundColor: 'white',
                          }}
                          className='blue-color f-14 fw-600 image-button-add'
                          onClick={() =>
                            handleAddClick(
                              viewOptions.id,
                              viewOptions.packName,
                              index,
                              viewOptions?.serviceHeading,
                              viewOptions?.packAmount
                            )
                          }
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {/* <Grid
                  item
                  xl={5}
                  lg={3}
                  md={3}
                  xs={12}
                  sm={12}
                  style={{ border: '2px solid black' }}
                  className='image-container'
                >
                  <img src={viewOptions?.image} alt='Logo' />
                  {counts[`${viewData?.serviceHeading}-${index}`] ? (
                    <Button
                      variant='outlined'
                      className='image-button'
                      size='small'
                      style={{
                        backgroundColor: 'white',
                        border: '2px solid rgb(110, 66, 229)',
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleRemoveClick(
                            viewData.id,
                            viewData.packName,
                            index
                          )
                        }
                      >
                        -
                      </Button>
                      <Typography sx={{ mx: 2 }}>
                        {counts[`${viewData?.serviceHeading}-${index}`]
                          ?.count || 0}
                      </Typography>
                      <Button
                        onClick={() =>
                          handleAddClick(viewData.id, viewData.packName, index)
                        }
                      >
                        +
                      </Button>
                    </Button>
                  ) : (
                    <Button
                      variant='outlined'
                      onClick={() =>
                        handleAddClick(viewData.id, viewData.packName, index)
                      }
                      className='blue-color f-14 fw-600 image-button'
                      style={{ backgroundColor: 'white' }}
                    >
                      Add
                    </Button>
                  )}
                </Grid> */}
              </Grid>
            ))}
          </Grid>
        )}
        {viewData?.frequentlyAdded && (
          <Card className='my-20 p-10'>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='py-20'
              >
                <Typography variant='h4' className='fw-700 f-28'>
                  Frequently Added together
                </Typography>
              </Grid>{' '}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                columnGap={2}
              >
                {viewData?.frequentlyAdded?.map(
                  (frequentData?: any, index?: any) => (
                    <Grid
                      item
                      xl={4}
                      container
                      className='gray-border py-10 px-10'
                    >
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <img src={frequentData?.image} />
                      </Grid>
                      <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                        <Typography>{frequentData?.name}</Typography>
                      </Grid>
                      <Grid item xl={11} lg={11} md={11} sm={12} xs={12}>
                        <Typography>{frequentData?.amount}</Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Card>
        )}
        {heading === t('BATHROOM_CLEANING') ? (
          <>
            <Card className='my-20 p-10'>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                  <img src={included1} style={{ width: '100%' }} alt='img' />
                </Grid>
              </Grid>
            </Card>
            <Card className='my-20 p-10'>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                  <img src={excluded} style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </Card>
            <Card className='my-20 p-10'>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                <img src={included5} style={{ width: '100%' }} />
              </Grid>
            </Card>
            <Card className='my-20 p-10'>
              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className='py-20'
                >
                  <Typography variant='h4' className='fw-7'>
                    Spotless Bathroom
                  </Typography>
                </Grid>{' '}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                  <img src={spotless} style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </Card>
            <Card className='my-20 p-10'>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                  <img src={cleaning} style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </Card>
          </>
        ) : heading === t('AIR_COOLER_REPAIR_SERVICE') ? (
          viewData?.packName ===
          'Air Cooler servicing  & side pad Replacement' ? (
            <>
              <Card className='my-20 p-10'>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                    <img src={acIncluded} style={{ width: '100%' }} alt='img' />
                  </Grid>
                </Grid>
              </Card>
              <Card className='my-20 p-10'>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                    <img src={acExcluded} style={{ width: '100%' }} alt='img' />
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <Card className='my-20 p-10'>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                  <img src={acHowWorks} style={{ width: '100%' }} alt='img' />
                </Grid>
              </Grid>
            </Card>
          )
        ) : (
          <></>
        )}
        {frequentlyAskedQuestion ? (
          <Card className='my-20 p-10'>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='py-20'
              >
                <Typography className='fw-700 f-16'>
                  Frequently asked questions
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
                {frequentlyAskedQuestion?.map(
                  (frequentData?: any, index?: any) => (
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={'py-10'}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          <Typography className='fw-600 f-14 font-os-semi-bold'>
                            {frequentData?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className='fw-300 f-14'>
                            {frequentData?.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Card>
        ) : (
          <></>
        )}

        <Card>
          <ReviewCard
            rating={viewData?.rating}
            reviewCount={viewData?.booking}
            startValues={viewData?.startValues}
          />
        </Card>
        <Card>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ p: 4 }}>
                <ReviewFilter
                  reviews={viewData?.reviews}
                  rating={ratingFilters}
                  sortBy={sortByFilter}
                  others={otherFilters}
                  onApplyFilters={handleApplyFilters}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions style={{ padding: '10px' }}>
        <Button
          variant='contained'
          size='large'
          fullWidth
          onClick={viewDetailsClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ViewDetailsBathRoom;
