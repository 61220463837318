import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './home.css';
import HomeCarousel1 from '../../images/home/homeCarousel1.png';
import HomeCarousel2 from '../../images/home/homeCarousel2.png';
import HomeCarousel3 from '../../images/home/homeCarousel3.png';
import HomeCarousel4 from '../../images/home/homeCarousel4.png';
import HomeCarousel5 from '../../images/home/homeCarousel5.png';
import HomeCarousel6 from '../../images/home/homeCarousel6.png';
import beautyCarousel1 from '../../images/beauty/beautyCarousel1.png';
import beautyCarousel2 from '../../images/beauty/beautyCarousel2.png';
import beautyCarousel3 from '../../images/beauty/beautyCarousel3.png';
import beautyCarousel4 from '../../images/beauty/beautyCarousel4.png';
import beautyCarousel5 from '../../images/beauty/beautyCarousel5.png';

interface UserProps {
  type?: any;
}

const ServicesCarousel: React.FC<UserProps> = ({ type }) => {
  const items = [
    { image: HomeCarousel1 },
    { image: HomeCarousel2 },
    { image: HomeCarousel3 },
    { image: HomeCarousel4 },
    { image: HomeCarousel5 },
    { image: HomeCarousel6 },
  ];
  const beautyItems = [
    { image: beautyCarousel1 },
    { image: beautyCarousel2 },
    { image: beautyCarousel3 },
    { image: beautyCarousel4 },
    { image: beautyCarousel5 },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };
  const selectedItems = type === 'beauty' ? beautyItems : items;
  return (
    <Carousel
      responsive={responsive}
      arrows={true}
      showDots={false}
      infinite={false}
      autoPlay={false}
      draggable={false}
    >
      {selectedItems.map((item, index) => (
        <div
          key={index}
          className='parent-img p-10'
          onClick={() => handleImageClick(index)}
        >
          <img src={item.image} alt='img' className='carousel-image' />
        </div>
      ))}
    </Carousel>
  );
};

export default ServicesCarousel;
