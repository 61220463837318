import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete/Autocomplete';
import { Grid, InputAdornment, Typography } from '@mui/material';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import './searchBox.css';

type OptionType = {
  label: string;
};
const SearchBox: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<OptionType[]>([]);
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);
  const [recentSearches, setRecentSearches] = useState<OptionType[]>([]);

  const placeholders = ['HairCut', 'Waxing', 'Massage'];

  const data: OptionType[] = [
    { label: 'Salon' },
    { label: 'Massage for men' },
    { label: 'Party makeup' },
    { label: 'Skin Clinic' },
    { label: 'Spa luxe' },
    { label: 'Skin Clinic Video consultation' },
  ];

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(newInputValue);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: OptionType | string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionType>
  ) => {
    if (newValue && typeof newValue !== 'string') {
      setRecentSearches((prev) => {
        const updated = [...prev];
        if (
          !updated.some((item) => item.label === (newValue as OptionType).label)
        ) {
          updated.unshift(newValue as OptionType);
          if (updated.length > 2) updated.pop();
        }
        return updated;
      });
    }
    console.log(newValue);
  };

  const handleOpen = () => {
    setOptions(
      data.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 1000);

    return () => clearInterval(timer);
  }, [placeholders.length]);

  const combinedOptions = [
    ...recentSearches,
    ...data.filter(
      (option) =>
        !recentSearches.some((recent) => recent.label === option.label)
    ),
  ];
  return (
    <Autocomplete
      freeSolo
      className='search-box'
      open={options.length > 0}
      onOpen={handleOpen}
      onClose={() => setOptions([])}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={combinedOptions}
      groupBy={(option) => {
        if (recentSearches.some((search) => search.label === option.label)) {
          return 'Recents';
        }
        return 'Trending Searches';
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      renderOption={(props, option) => (
        <Grid container>
          <Grid item>
            <li {...props}>
              <TrendingUpIcon className='option-icon' />
              <span className='option-label'>
                {(option as OptionType).label}
              </span>
            </li>
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          placeholder={`Search for ${placeholders[placeholderIndex]}`}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBox;
