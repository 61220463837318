import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Grid,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './product.css';
interface Review {
  heading: string;
  review: string;
  star: string;
  date: any;
  name?: any;
  serviceName?: any;
}

interface ReviewFilterProps {
  reviews: Review[];
  rating: any;
  sortBy: any;
  others: any;
  onApplyFilters?: any;
}

const ReviewFilter: React.FC<ReviewFilterProps> = ({
  reviews,
  rating,
  sortBy,
  others,
  onApplyFilters,
}) => {
  const [open, setOpen] = React.useState(false);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [value, setValue] = React.useState('1');
  const [data] = useState([
    { id: 5, name: '5 Star' },
    { id: 4, name: '4 Star' },
    { id: 3, name: '3 Star' },
    { id: 2, name: '2 Star' },
    { id: 1, name: '1 Star' },
  ]);
  const [otherData] = useState([
    { id: 1, name: 'In my area' },
    { id: 2, name: 'Frequent User' },
  ]);
  const [sortData] = useState([
    { id: 1, name: 'Recent' },
    { id: 2, name: 'Most Detailed' },
  ]);

  const [selectedSortValue, setSelectedSortValue] = useState<string | null>(
    null
  );
  const [ratingItems, setRatingItems] = useState<number[]>([]);
  const [otherItems, setOtherItems] = useState<number[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    const appliedFilters = {
      rating: ratingItems,
      sortBy: selectedSortValue,
      others: otherItems,
    };
    if (onApplyFilters) onApplyFilters(appliedFilters);
    console.log('Applied Filters:', appliedFilters);
  };

  const handleButtonClick = (filter: string) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter((item) => item !== filter));
    } else {
      setActiveFilters((prevFilters) => [...prevFilters, filter]);
    }
  };
  const filteredReviews = reviews.filter((review) => {
    const reviewDate = new Date(review.date);

    const today = new Date();
    const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));

    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const formattedOneMonthAgo = formatDate(oneMonthAgo);

    const ratingValue = Number(review.star);
    const matchesRating =
      ratingItems.length === 0 ||
      ratingItems.some((rating) => {
        return rating <= ratingValue && ratingValue < rating + 1;
      });

    const matchesLocation =
      !otherItems.includes(1) || review.heading === 'in my area';
    const matchesUserType =
      !otherItems.includes(2) || review.heading === 'frequent';

    const reviewDateFormatted = formatDate(reviewDate);
    const matchesDate =
      selectedSortValue !== '1' || reviewDateFormatted >= formattedOneMonthAgo;

    const matchesLength =
      selectedSortValue !== '2' || review.review.length > 20;

    const matchesFilters =
      activeFilters.length === 0 ||
      activeFilters.some((filter) =>
        review.heading.toLowerCase().includes(filter.toLowerCase())
      );

    return (
      matchesRating &&
      matchesLocation &&
      matchesUserType &&
      matchesFilters &&
      matchesDate &&
      matchesLength
    );
  });

  const handleSelectSortItem = (id: number) => {
    setSelectedSortValue(
      selectedSortValue === id.toString() ? null : id.toString()
    );
  };

  const handleSelectRatingItem = (id: number) => {
    setRatingItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectOtherItem = (id: number) => {
    setOtherItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const calculateActiveFiltersCount = () => {
    let count = 0;
    if (ratingItems.length > 0) count += ratingItems.length;
    if (selectedSortValue) count += 1;
    if (otherItems.length > 0) count += otherItems.length;
    return count;
  };

  const activeFiltersCount = calculateActiveFiltersCount();
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='py-20'
          container
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xl={11} lg={6} md={6} sm={10} xs={12}>
            <Typography className='fw-700 f-28 font-os-semi-bold'>
              All Reviews
            </Typography>
          </Grid>
          <Grid item xl={1} lg={6} md={6} sm={2} xs={12}>
            <Button onClick={handleClickOpen}>
              Filter {activeFiltersCount > 0 && `(${activeFiltersCount})`}
            </Button>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            className={
              activeFilters.includes('detailed')
                ? 'gray-button mx-10 my-5 f-14 fw-600'
                : 'black-button mx-10 my-5 f-14 fw-600 font-os-semi-bold'
            }
            onClick={() => handleButtonClick('detailed')}
          >
            Detailed
          </Button>
          <Button
            className={
              activeFilters.includes('in my area')
                ? 'gray-button mx-10 my-5 f-14 fw-600'
                : 'black-button mx-10 my-5 f-14 fw-600 font-os-semi-bold'
            }
            onClick={() => handleButtonClick('in my area')}
          >
            In My Area
          </Button>
          <Button
            className={
              activeFilters.includes('frequent')
                ? 'gray-button mx-10 my-5 f-14 fw-600'
                : 'black-button mx-10 my-5 f-14 fw-600 font-os-semi-bold'
            }
            onClick={() => handleButtonClick('frequent')}
          >
            Frequent Users
          </Button>
        </Grid>
        {filteredReviews.map((review, index) => (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            className='py-20'
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='justify-space-between'
              container
            >
              <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                <Typography className='fw-600 f-20'>{review.name}</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                {review?.star >= '4' ? (
                  <Button
                    color='success'
                    variant='contained'
                    startIcon={<StarIcon />}
                    className='f-16'
                    size='small'
                  >
                    {review?.star}
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    startIcon={<StarIcon />}
                    style={{ backgroundColor: 'rgb(219, 109, 36)' }}
                    className='f-16'
                    size='small'
                  >
                    {review?.star}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography className='f-14 gray-font'>{review?.date}</Typography>
            </Grid>{' '}
            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
              <Typography className='f-14 gray-font'>
                {review?.serviceName}
              </Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='mt-10'
            >
              <Typography className='fw-400 f-14 font-OpenSans-Regular'>
                {review?.review}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='fw-600 f-20'
        >
          Filters
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label='tab list'>
                  <Tab label='Rating' value='1' />
                  <Tab label='Sort By' value='2' />
                  <Tab label='Others' value='3' />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <FormGroup>
                  {data.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={ratingItems.includes(item.id)}
                          onChange={() => handleSelectRatingItem(item.id)}
                          className='fw-400 f-14'
                        />
                      }
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </TabPanel>
              <TabPanel value='2'>
                <FormControl component='fieldset'>
                  <RadioGroup value={selectedSortValue}>
                    {sortData.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.id.toString()}
                        control={<Radio />}
                        label={item.name}
                        onChange={() => handleSelectSortItem(item.id)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </TabPanel>
              <TabPanel value='3'>
                <FormGroup>
                  {otherData.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={otherItems.includes(item.id)}
                          onChange={() => handleSelectOtherItem(item.id)}
                        />
                      }
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </TabPanel>
            </TabContext>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            size='large'
            fullWidth
            onClick={() => {
              setRatingItems([]);
              setSelectedSortValue(null);
              setOtherItems([]);
              setActiveFilters([]);
              setOpen(false);
            }}
          >
            Reset
          </Button>
          <Button
            variant='contained'
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewFilter;
