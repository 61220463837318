import React, { useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes/constant';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SearchBox from '../../Components/SearchBox';
import Logo from '../../images/yaa-logi1.png';
import LocationSearchBox from '../../Components/LocationSearchBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HommeScreen from '../Home/HomeScreen';
import BeautyScreen from '../Beauty/BeautyScreen';
import NativeScreen from '../Native/NativeScreen';
import { useTranslation } from 'react-i18next';
import SimplePopper from '../../Components/SimplePopper';
import MenuDrawer from './MenuDrawer';
import LoginModal from './LoginModal';
import OtpModal from './OtpModal';
import LanguageSelector from './LanguageSelector';
import Footer from './Footer';
import MiniDrawer from './MiniVariant';
import AppDrawer from './AppDrawer';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'black',
          fontWeight: 'bold',
          fontSize: '14px',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'gray',
          '&.Mui-selected': {
            color: 'black',
            fontWeight: '600',
            fontSize: '14px',
            textTransform: 'capitalize',
          },
        },
      },
    },
  },
});
const Dashboard: React.FC = () => {
  const isLargeScreen = useMediaQuery('(min-width:800px)');
  const location = useLocation();
  const { t } = useTranslation();
  const value = location.pathname;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editNumber, setEditNumber] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [loginApproval, setLoginApproval] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [tabValue, setTabValue] = useState(APP_ROUTES.HOME);
  const handleOtpClose = () => {
    setOtpOpen(false);
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setAnchorEl(null);
  };
  const handleLanguageOpen = () => {
    setLanguageOpen(true);
    setAnchorEl(null);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
    setTabValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? 'simple-popper' : undefined;
  useEffect(() => {
    navigate('/home');
  }, []);
  useEffect(() => {
    if (loginApproval) {
      setOtpOpen(true);
    }
  }, [loginApproval]);
  useEffect(() => {
    setTabValue(APP_ROUTES?.HOME);
    navigate('/home');
  }, [languageOpen]);
  return (
    <ThemeProvider theme={theme}>
      {location.pathname === APP_ROUTES.RATE_CARD ? (
        <Grid container>
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              width: '100%',
              position: 'fixed',
              zIndex: 1,
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              style={{
                color: 'white',
                backgroundColor: 'black',
                height: '120px',
              }}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className='px-10 f-18 fw-800 mt-40'>
                  Rate card
                </Typography>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                style={{
                  backgroundColor: 'white',
                  height: '50px',
                  borderBottom: '2px solid gray',
                }}
                className='px-10 f-13 fw-500 mt-30'
                textAlign={'center'}
              >
                Labour charges are capped at 299 per appliances
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='px-30 py-30 mt-50'
          >
            <Outlet />
          </Grid>
        </Grid>
      ) : isLargeScreen ? (
        <Grid container rowGap={1}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              backgroundColor: 'white',
              zIndex: 1,
            }}
            className='mb-30 px-5'
          >
            <Grid
              container
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              item
              columnSpacing={1}
              className='px-5'
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Grid item xl={1} lg={1} md={1} sm={1.5}>
                <img
                  src={Logo}
                  alt='Logo'
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={4}>
                <Tabs
                  value={location.pathname}
                  onChange={handleTabChange}
                  aria-label='navigation tabs'
                >
                  <Tab
                    label={t('BEAUTY')}
                    value={APP_ROUTES?.BEAUTY}
                    className='text-capitalize'
                  />
                  <Tab
                    label={t('HOME')}
                    value={APP_ROUTES?.HOME}
                    className='text-capitalize'
                  />
                  <Tab
                    label={t('NATIVE')}
                    value={APP_ROUTES?.NATIVE}
                    className='text-capitalize'
                  />
                </Tabs>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={2.5}>
                <Button
                  startIcon={<LocationOnIcon sx={{ fontSize: 18 }} />}
                  variant='outlined'
                  size='small'
                  className='f-14 text-capitalize'
                  onClick={handleClickOpen}
                  fullWidth
                  endIcon={<KeyboardArrowDownIcon sx={{ fontSize: 18 }} />}
                  sx={{
                    borderColor: '#D3D3D3',
                    color: 'black',
                    height: '53px',
                    marginTop: '3px',
                    '&:hover': {
                      borderColor: 'black',
                    },
                    '&.Mui-focused': {
                      borderColor: 'black',
                    },
                  }}
                >
                  Connaught Place, New....
                </Button>
              </Grid>
              <Grid item xl={2.5} lg={2} md={2.5} sm={2.4}>
                <SearchBox />
              </Grid>
              <Grid
                item
                xl={2}
                md={0.7}
                lg={2}
                sm={1.5}
                className='justify-center ml-4'
              >
                <IconButton size='large'>
                  <ShoppingCartIcon fontSize='inherit' />
                </IconButton>
                <IconButton size='large' onClick={handleClick}>
                  <AccountCircleIcon fontSize='inherit' />
                </IconButton>
              </Grid>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Divider sx={{ bgcolor: '#D3D3D3', mt: 2, mb: 2 }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='px-30 py-30 mt-50'
          >
            <Outlet />
          </Grid>

          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Footer />
          </Grid>

          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: '10px',
              },
            }}
          >
            <DialogContent>
              {' '}
              <TextField
                fullWidth
                placeholder='Search for Location/City/Appartments'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ArrowBackIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <DialogContentText id='alert-dialog-slide-description'>
                {' '}
                <Button
                  startIcon={<LocationOnIcon sx={{ fontSize: 18 }} />}
                  size='large'
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  use Current Location
                </Button>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Popper
            id={id}
            open={openPopper}
            anchorEl={anchorEl}
            style={{ zIndex: 1 }}
          >
            <Paper>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary='Login' onClick={handleLoginOpen} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary='Select Language'
                      onClick={handleLanguageOpen}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Popper>
          <LoginModal
            loginOpen={loginOpen}
            handleLoginClose={handleLoginClose}
            setMobileNumber={setMobileNumber}
            setLoginOpen={setLoginOpen}
            mobileNumber={mobileNumber}
            editNumber={editNumber}
            setOtpOpen={setOtpOpen}
            setLoginApproval={setLoginApproval}
          />
          <OtpModal
            otpOpen={otpOpen}
            handleOtpClose={handleOtpClose}
            mobileNumber={mobileNumber}
            setLoginOpen={setLoginOpen}
            setOtpOpen={setOtpOpen}
            setEditNumber={setEditNumber}
          />
          <LanguageSelector
            languageOpen={languageOpen}
            setLanguageOpen={setLanguageOpen}
            contentType={'DASHBOARD'}
          />
        </Grid>
      ) : (
        <MenuDrawer />
      )}
    </ThemeProvider>
  );
};

export default Dashboard;
