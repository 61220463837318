import React from 'react';

import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
const CartScreen: React.FC = () => {
  return (
    <Box>
      <Typography>CartScreen</Typography>
    </Box>
  );
};

export default CartScreen;
