// CartContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CartItem {
  packName: string;
  count: number;
  packAmount: number;
  discountPrice: number;
}

interface CartContextType {
  totalCart: CartItem[];
  setTotalCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [totalCart, setTotalCart] = useState<CartItem[]>([]);

  return (
    <CartContext.Provider value={{ totalCart, setTotalCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
