import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../../Components/FormInput';
import CloseIcon from '@mui/icons-material/Close';

interface UserProps {
  loginOpen?: any;
  setMobileNumber?: any;
  handleLoginClose?: any;
  setLoginOpen?: any;
  mobileNumber?: any;
  editNumber?: true | false;
  setOtpOpen?: any;
  setLoginApproval?: any;
}

const LoginModal: React.FC<UserProps> = ({
  loginOpen,
  setMobileNumber,
  handleLoginClose,
  setLoginOpen,
  mobileNumber,
  editNumber,
  setOtpOpen,
  setLoginApproval,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: '',
    },
  });
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(true);

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onSubmit = (data: any) => {
    console.log('dataa', data);
    setMobileNumber(data.phone);
    setLoginApproval(true);
    setLoginOpen(false);
    setOtpOpen(true);
  };
  const mobileNo = watch('phone');
  const parseMobileNo = (mobileNo: any) => {
    const allowedFirstDigits = ['6', '7', '8', '9'];
    const numberPattern = /\d+/g;
    const matches: any[] | null = String(mobileNo).match(numberPattern);
    if (matches) {
      const digits = matches.join('');
      if (allowedFirstDigits.includes(digits.charAt(0))) {
        return digits.substring(0, 10);
      }
    }
    return '';
  };

  React.useEffect(() => {
    if (mobileNo) {
      setValue('phone', parseMobileNo(mobileNo));
    }
    const mobileNumber = getValues('phone');
    setIsButtonDisabled(mobileNumber.length !== 10);
  }, [mobileNo]);
  React.useEffect(() => {
    if (!loginOpen) {
      reset();
    }
  }, [loginOpen, reset]);

  React.useEffect(() => {
    if (!loginOpen) {
      reset();
      setIsButtonDisabled(true);
      setChecked(true);
    } else if (editNumber && mobileNumber) {
      setValue('phone', mobileNumber);
    }
  }, [loginOpen, reset, editNumber, mobileNumber, setValue]);
  return (
    <React.Fragment>
      <Dialog
        open={loginOpen}
        onClose={handleLoginClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <IconButton
          aria-label='close'
          onClick={handleLoginClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h5' component='h2'>
            {t('LOGIN_SIGINUP')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {' '}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              columnSpacing={3}
              style={{ padding: '10px' }}
              rowSpacing={3}
            >
              <Grid item xs={12} xl={12}>
                <FormInput
                  name='phone'
                  type='text'
                  placeholder={t('MOBILE_NUMBER_PLACEHOLDER')}
                  control={control}
                  className='input-from'
                  startAdornment={'91'}
                  fullWidth
                  rules={{
                    required: {
                      value: true,
                      message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                    },
                    maxLength: {
                      value: 10,
                      message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
                    },
                    minLength: {
                      value: 10,
                      message: `${t(
                        'MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'
                      )}`,
                    },
                  }}
                  inputProps={{
                    ...register('phone', {}),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: 'black',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label='Get Order Updates on Whatsapp'
                />{' '}
              </Grid>
              <Grid item xl={12} xs={12}>
                <Button
                  variant='contained'
                  type='submit'
                  fullWidth
                  //   onClick={handleLoginClose}
                  disabled={isButtonDisabled}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default LoginModal;
