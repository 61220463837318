import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const availableLanguages = ['ta', 'en'];
const options = {
  order: ['navigator', 'htmlTag', 'path', 'subdomain'],
  checkWhitelist: true,
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('ln') || 'en',
    fallbackLng: 'en',
    debug: false,
    whitelist: availableLanguages,
    detection: options,
    useSuspense: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
