import * as React from 'react';

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  Card,
  Button,
  Skeleton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import uc from '../../images/home/uc-professional-bathroom.png';
import './product.css';
interface UserProps {
  image?: any;
  heading?: any;
  subHeading?: any;
  rating?: any;
  booking?: any;
  guide?: any;
  serviceList?: any;
  onServiceClick?: any;
  serviceButton?: any;
  handleViewService?: any;
}

const ProductList: React.FC<UserProps> = ({
  image,
  heading,
  guide,
  rating,
  booking,
  serviceList,
  onServiceClick,
  serviceButton,
  handleViewService,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [CleaningGuideOpen, setCleaningGuideOpen] = React.useState(false);
  const handleCleaningGuide = () => {
    setCleaningGuideOpen(true);
  };
  const handleCleaningGuideClose = () => {
    setCleaningGuideOpen(false);
  };
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Set loading to false after 2 seconds

    return () => clearTimeout(timer); // Clean up the timer when the component unmounts
  }, []);

  return (
    <Grid container rowSpacing={1}>
      {loading ? (
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='mt-10 py-20'
        >
          <Skeleton animation='wave' height={10} className='py-10' />
          <Skeleton animation='wave' height={10} className='py-10' />
        </Grid>
      ) : (
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h1 className='dashboard-header font-os-semi-bold'>{heading}</h1>
          </Grid>
          <Grid item xl={1.5} lg={1.5} md={1} sm={1} xs={1}>
            <Avatar sx={{ bgcolor: '#0F8ECA', width: 24, height: 24 }}>
              <StarOutlineIcon />
            </Avatar>
          </Grid>
          <Grid item xl={10.5} lg={10.5} md={11} sm={11} xs={11}>
            <Typography className='text-dotted-underline f-16'>
              {rating} ({booking} Booking)
            </Typography>
          </Grid>
          {guide && (
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
              <Button onClick={handleCleaningGuide}>
                <List>
                  <ListItem>
                    <FavoriteBorderIcon
                      sx={{
                        marginRight: '10px',
                        color: '#00539A',
                        backgroundColor: 'rgba(245, 245, 245, 1.00)',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '20px',
                      }}
                    />
                    <ListItemText primary={guide} className='black-captital' />
                    <ArrowForwardIosIcon className='black-captital ml-10' />
                  </ListItem>
                </List>
              </Button>
            </Grid>
          )}
          {serviceButton && (
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={10}
              className='py-20 mt-20'
            >
              <Button
                variant='contained'
                size='large'
                className='text-capitalize br-10 h-250 f-16 fw-600'
                fullWidth
                onClick={handleViewService}
              >
                View Services
              </Button>
            </Grid>
          )}
        </>
      )}

      <Dialog
        open={CleaningGuideOpen}
        onClose={handleCleaningGuideClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <IconButton
          aria-label='close'
          onClick={handleCleaningGuideClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container className='my-20 p-10'>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container>
              <img src={uc} style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default ProductList;
