import * as React from 'react';

import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ProductScreen from '../ProductDescription/ProductSceen';
import superSaver from '../../images/home/supeSaver.png';
import miniService from '../../images/home/miniService.png';
import kitchenCleaning from '../../images/home/kitchenCleaning.png';
import bathroomCleaning from '../../images/home/bathroomCleaning.png';
import exhaustFan from '../../images/home/exhausFan.png';
import bathroom2 from '../../images/home/bathroom-2.png';
import bathroom1 from '../../images/home/cleaning-1.png';
import bathroom3 from '../../images/home/cleaning-3.png';
import '../ProductDescription/product.css';
import { CartProvider, useCart } from '../ProductDescription/CartProvider';
import AddtoCart from '../ProductDescription/AddtoCart';
import ProductDetails from '../ProductDescription/ProductDetails';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes/constant';
import ProductList from '../ProductDescription/ProductList';
import ImageCarousel from '../../Components/ImageCarousel';
interface ServiceDetail {
  serviceAmount: string;
  packName: string;
  packAmount: string;
  time: string;
  discountPrice: string;
  image: string;
  rating: string;
  booking: string;
  product: string;
  description: string[];
  frequentlyAdded?: any;
  serviceHeading?: any;
  reviews?: any;
  startValues?: any;
}
interface GroupedService {
  [key: string]: ServiceDetail[];
}

interface UserProps {
  image?: any;
  heading?: any;
  subHeading?: any;
}

const BathroomScreen: React.FC<UserProps> = ({
  image,
  heading,
  subHeading,
}) => {
  const { t } = useTranslation();
  const { totalCart, setTotalCart } = useCart();
  const mainCarouselImages = [bathroom1, bathroom3];
  const serviceList = [
    {
      image: superSaver,
      heading: 'Super Saver Deals',
    },
    {
      image: kitchenCleaning,
      heading: 'Kitchen Cleaning',
    },
    {
      image: bathroomCleaning,
      heading: 'Bathroom Cleaning',
    },
    {
      image: miniService,
      heading: 'Mini Services',
    },
  ];

  const productData: ServiceDetail[] = [
    {
      serviceHeading: 'Super Saver Deals',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Super Saver Deals- 2 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      frequentlyAdded: [
        {
          image: exhaustFan,
          name: 'Exhaust Fan for kitchen',
          amount: '82',
        },
        {
          image: exhaustFan,
          name: 'Exhaust Fan for kitchen',
          amount: '82',
        },
      ],
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },
    {
      serviceHeading: 'Super Saver Deals',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Calssic Cleaning - 3 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },

    {
      serviceHeading: 'Bathroom Cleaning',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Bathroom Cleaning - 2 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },
    {
      serviceHeading: 'Bathroom Cleaning',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Premium Cleaning - 2 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },

    {
      serviceHeading: 'Kitchen Cleaning',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Kitchen Cleaning - 2 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },
    {
      serviceHeading: 'Kitchen Cleaning',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Elite Cleaning - 2 bathroom pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },

    {
      serviceHeading: 'Mini Services',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Mini Service - 2  pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },
    {
      serviceHeading: 'Mini Services',
      serviceAmount: 'RS 404 per bathroom',
      packName: 'Mini Service Deals - 2  pack',
      packAmount: '808',
      time: '2 hrs',
      discountPrice: '890',
      image: bathroom2,
      rating: '4.8',
      booking: '6.7M',
      product: bathroom2,
      description: [
        'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc.',
        'Dirt & stain removal for spotless shine at only ₹404/bathroom ',
      ],
      startValues: [
        { star: '1', value: '2', review: '550' },
        { star: '2', value: '5', review: '850' },
        { star: '3', value: '7', review: '650' },
        { star: '4', value: '50', review: '50K' },
        { star: '5', value: '90', review: '1.1 M' },
      ],
      reviews: [
        {
          heading: 'frequent',
          name: 'Vijay Basavarajappa',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'Bathroom is cleaned well.But it is incomplete when ceiling/roof was not dusted. Window was not cleaned.It was mentioned as 1 person would be coming for service, but two people turned out which comes as a surprise.',
          star: '5',
          date: '5-08-2024',
        },
        {
          heading: 'in my area',
          name: 'Marlene Peacock',
          serviceName:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          review:
            'For Classic Bathroom Cleaning, Gas Stove (Hob) Cleaning, Full Kitchen Cleaning, 2 Ceiling fan cleaning',
          star: '3.4',
          date: '15-07-2023',
        },
        {
          heading: 'most detailed',
          name: 'Karthik',
          serviceName: 'For Classic Bathroom Cleaning',
          review:
            'He did very good job. . He was very professional and bathroom looks clean first time .I have tried 20 professionals before this guy did excellent job..',
          star: '3.4',
          date: '5-2-2024',
        },
      ],
    },
  ];
  const frequentlyAskedQuestion = [
    {
      question: 'Wiil i have to provide any chemicals or cleaning agent?',
      answer:
        'No, our professionals carry all chemicals and equipment required for the service.',
    },
    {
      question: ' Which chemicals will be used during bathroom cleaning?',
      answer:
        ' The two main chemicals used are SwipePro Bathroom cleaner and Diversey Suma Multi.',
    },
    {
      question:
        'What will happen if theres no electricity at my home during the service?',
      answer:
        'In such cases, customers can get the service rescheduled with the help of Urban Companys customer care.',
    },
    {
      question: 'Will the professionals follow all safety measures? ',
      answer:
        'Yes. Our professionals will take all the necessary measures like social distancing, wearing a mask, etc.',
    },
    {
      question: 'How frequently should I book this service?',
      answer:
        'You should ideally book this service once every month for a clean and hygienic bathroom.',
    },
    {
      question:
        'My bathroom & toilet are in two separate areas, will both be covered?',
      answer: 'Yes, both areas will be cleaned',
    },
  ];

  const groupByServiceHeading = (data: ServiceDetail[]): GroupedService => {
    return data.reduce((acc: GroupedService, item: ServiceDetail) => {
      if (!acc[item.serviceHeading]) {
        acc[item.serviceHeading] = [];
      }
      acc[item.serviceHeading].push(item);
      return acc;
    }, {});
  };
  const groupedData = groupByServiceHeading(productData);
  const handleClick = (serviceData: any) => {
    window.location.hash = `#service-${serviceData?.heading}`;
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Grid container>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        container
        columnSpacing={1}
        className='mt-30'
      >
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={4}
          xs={12}
          className='px-10 fixed-section'
          container
        >
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <ProductList
              heading={t('BATHROOM_CLEANING')}
              rating={'4.71'}
              booking={'131K'}
              serviceButton={false}
              serviceList={serviceList}
              guide={'UC Professional Cleaning Guide'}
            />
          </Grid>{' '}
          {serviceList && (
            <Grid
              item
              container
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              rowSpacing={2}
              className='gray-border px-5 mt-10'
            >
              <Grid item xs={12} lg={12} md={12} sm={12} xl={12} className=''>
                <Typography className='font-os-semi-bold fw-600 gray-font py-5'>
                  Select a Service
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                xl={12}
                container
                rowSpacing={2}
              >
                {serviceList?.map((serviceData?: any, index?: any) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    xl={4}
                    lg={4}
                    md={6}
                    container
                    rowSpacing={2}
                    onClick={() => handleClick(serviceData)}
                    key={index}
                  >
                    <Grid container xs={12} item rowSpacing={2}>
                      <Grid
                        item
                        xl={12}
                        xs={12}
                        lg={12}
                        md={12}
                        sm={12}
                        className='list-image'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: '10px',
                          height: '100px',
                        }}
                      >
                        <img src={serviceData?.image} alt='Logo' />
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        xs={12}
                        lg={12}
                        md={12}
                        sm={12}
                        textAlign={'center'}
                      >
                        <p className='f-12'>{serviceData?.heading}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={8} xs={12} container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='scrollable-section'
          >
            <ImageCarousel
              images={mainCarouselImages}
              infinite={true}
              autoPlay={true}
              showDots={true}
              responsive={responsive}
            />
          </Grid>

          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            columnSpacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              container
              className='mt-30'
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                container
                className='px-5 gray-border mt-50'
              >
                {Object.entries(groupedData).map(([heading, services]) => (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    xl={12}
                    key={heading}
                    className='px-20'
                    id={`service-${heading}`}
                  >
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <h2 className='f-28 fw-700 font-os-semi-bold'>
                        {heading}
                      </h2>
                    </Grid>
                    {/* <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <Link to={APP_ROUTES?.RATE_CARD}>
                        <p className='blue-text'>Spare part prices</p>
                      </Link>
                    </Grid> */}
                    <Grid container item xs={12} spacing={2}>
                      {services.map((product: ServiceDetail, index: number) => (
                        <ProductDetails
                          key={index}
                          mainCarouselImages={mainCarouselImages}
                          productData={product}
                          serviceHeading={heading}
                          totalCart={totalCart}
                          setTotalCart={setTotalCart}
                          serviceList={serviceList}
                          index={index}
                          frequentlyAskedQuestion={frequentlyAskedQuestion}
                          heading={t('BATHROOM_CLEANING')}
                          imageHeight={'bathroomImage'}
                          addHeight={'bathroomImageAdd'}
                        />
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={5}
              lg={5}
              className='fixed-section'
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                container
                className='mt-50'
              >
                <AddtoCart />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BathroomScreen;
