import React from 'react';

import acRepairService from '../../images/home/acRepairService.png';
import powerAcService from '../../images/home/powerAcService.png';
import intenseCleaning from '../../images/home/intenseCleaning.png';
import topLoad from '../../images/home/topLoad.png';
import hairCutMen from '../../images/beauty/hairCutMen.png';
import sofaCleaning from '../../images/home/sofaCleaning.png';
import tapRepair from '../../images/home/tapRepair.png';
import flushTankRepair from '../../images/home/flushTankRepair.png';
import tvRepair from '../../images/home/tvRepair.png';
import elysian from '../../images/beauty/elysian.png';
import hairDrim from '../../images/beauty/hairDrim.png';
import blowDry from '../../images/beauty/blowDry.png';
import starightening from '../../images/beauty/starightening.png';
import rootaTouchUp from '../../images/beauty/rootaTouchUp.png';
import starightSmooth from '../../images/beauty/starightSmooth.png';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import StarIcon from '@mui/icons-material/Star';

interface UserProps {
  type?: any;
}

const HairNailServices: React.FC<UserProps> = ({ type }) => {
  const { t } = useTranslation();

  const beautyData1 = [
    {
      image: hairDrim,
      service: 'Hair Trim',
      star: '4.72',
      review: '24K',
      price: '449',
    },
    {
      image: blowDry,
      service: 'Blow-dry : In Curl / Out Curl',
      star: '4.80',
      review: '28K',
      price: '499',
    },
    {
      image: starightening,
      service: 'Straightening',
      star: '4.90',
      review: '19K',
      price: '499',
    },
  ];
  const beautyData2 = [
    {
      image: starightSmooth,
      service: 'Blow-dry : Staright / Smooth',
      star: '4.80',
      review: '28K',
      price: '499',
    },
    {
      image: rootaTouchUp,
      service: 'Roots: Touch-up Color (Loreal Inoa)',
      star: '4.75',
      review: '3K',
      price: '1,299',
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleImageClick = (index: number) => {
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <Grid container className='py-20'>
      <Grid item xl={12} xs={12} lg={12} md={12} sm={12} className='py-10'>
        <Typography className='fw-600 f-36 font-os-semi-bold'>
          {t('NAIL_HAIR_SERVICES')}
        </Typography>
      </Grid>
      <Grid
        item
        xl={12}
        xs={12}
        lg={12}
        md={12}
        sm={12}
        className='py-10'
        container
      >
        <Grid item xl={12} xs={12} lg={12} md={12} sm={12} container>
          {beautyData1.map((noteData, index) => (
            <Grid item xl={2} lg={2} md={3} xs={12} sm={4} key={index}>
              <CardMedia
                component='img'
                height='250'
                image={noteData.image}
                alt={noteData.service}
                className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
              <CardContent>
                <Grid container className='ml-5' columnGap={1}>
                  <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                    <Typography className='fw-600 f-16'>
                      {noteData?.service}
                    </Typography>
                  </Grid>
                  <Grid item xl={0.5} xs={1} lg={0.5} md={0.5} sm={1}>
                    <StarIcon sx={{ fontSize: '14px' }} />
                  </Grid>
                  <Grid item xl={1.5} xs={2} lg={1.5} md={2} sm={2}>
                    <Typography className='fw400 f-14'>
                      {noteData?.star}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} xs={3} lg={3} md={3} sm={3}>
                    <Typography className='fw-400 f-14'>
                      ({noteData?.review})
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className='ml-5'
                  >
                    <Typography
                      gutterBottom
                      variant='subtitle1'
                      component='div'
                    >
                      ₹{noteData?.price}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          ))}
        </Grid>
        <Grid item xl={12} xs={12} lg={12} md={12} sm={12} container>
          {beautyData2.map((noteData, index) => (
            <Grid item xl={2} lg={2} md={3} xs={12} sm={4} key={index}>
              <CardMedia
                component='img'
                height='300'
                image={noteData.image}
                alt={noteData.service}
                className='carousel-image'
                style={{
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                onClick={() => handleImageClick(index)}
              />
              <CardContent>
                <Grid container className='ml-5' columnGap={1}>
                  <Grid item xl={12} xs={12} lg={12} md={12} sm={12}>
                    <Typography className='fw-600 f-16'>
                      {noteData?.service}
                    </Typography>
                  </Grid>
                  <Grid item xl={0.5} xs={1} lg={0.5} md={0.5} sm={1}>
                    <StarIcon sx={{ fontSize: '14px' }} />
                  </Grid>
                  <Grid item xl={1.5} xs={2} lg={1.5} md={2} sm={2}>
                    <Typography className='fw400 f-14'>
                      {noteData?.star}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} xs={3} lg={3} md={3} sm={3}>
                    <Typography className='fw-400 f-14'>
                      ({noteData?.review})
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className='ml-5'
                  >
                    <Typography
                      gutterBottom
                      variant='subtitle1'
                      component='div'
                    >
                      ₹{noteData?.price}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HairNailServices;
