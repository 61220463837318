import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import './product.css';
import ViewDetails from './ViewDetailsBathRoom';
import CartButton from '../../Components/CartButton';

interface ProductDetailsProps {
  productData?: any;
  serviceHeading: string;
  totalCart: any;
  setTotalCart: React.Dispatch<React.SetStateAction<TotalCount[]>>;
  index: any;
  frequentlyAskedQuestion?: any;
  startValues?: any;
  reviews?: any;
  heading?: any;
  serviceList?: any;
  mainCarouselImages?: any;
  imageHeight?: any;
  addHeight?: any;
}

interface TotalCount {
  packName: string;
  count: number;
  packAmount: number;
  discountPrice: number;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productData,
  serviceHeading,
  totalCart,
  setTotalCart,
  index,
  frequentlyAskedQuestion,
  startValues,
  reviews,
  serviceList,
  heading,
  mainCarouselImages,
  imageHeight,
  addHeight,
}) => {
  const { t } = useTranslation();
  const [viewDetailsOpen, setViewDetailOpen] = React.useState(false);
  const [viewData, setViewData] = React.useState();
  const [counts, setCounts] = React.useState<{
    [key: string]: { count: number; packAmount: number; discountPrice: number };
  }>({});

  const handleViewDetails = (index?: any, productData?: any) => {
    setViewDetailOpen(true);

    setViewData(productData);
  };
  const handleViewDetailsClose = () => {
    setViewDetailOpen(false);
  };
  const updateTotalCounts = (
    productName: string,
    change: number,
    packAmount: number,
    discountPrice: number
  ) => {
    setTotalCart((prevTotalCart) => {
      const existingProduct = prevTotalCart.find(
        (item) => item.packName === productName
      );
      console.log('existingProduct---1', existingProduct);
      if (existingProduct) {
        const updatedTotalCart = prevTotalCart.map((item) =>
          item.packName === productName
            ? { ...item, count: item.count + change, packAmount, discountPrice }
            : item
        );

        return updatedTotalCart.filter((item) => item.count > 0);
      } else if (change > 0) {
        return [
          ...prevTotalCart,
          { packName: productName, count: change, packAmount, discountPrice },
        ];
      }

      return prevTotalCart;
    });
  };

  const handleAddClick = (
    productId: string,
    productName: string,
    index: number,
    optionsServiceHeading?: any,
    packAmountOptions?: any
  ) => {
    const key = `${
      optionsServiceHeading ? optionsServiceHeading : serviceHeading
    }-${index}`;

    setCounts((prevCounts) => {
      const currentData = prevCounts[key] || {
        count: 0,
        packAmount: packAmountOptions
          ? packAmountOptions
          : productData?.packAmount || 0,
        discountPrice: productData?.discountPrice || 0,
      };
      return {
        ...prevCounts,
        [key]: {
          count: currentData.count + 1,
          packAmount: packAmountOptions
            ? packAmountOptions
            : currentData.packAmount,
          discountPrice: currentData.discountPrice,
        },
      };
    });

    updateTotalCounts(
      productName,
      1,
      packAmountOptions ? packAmountOptions : productData?.packAmount || 0,
      productData?.discountPrice || 0
    );
  };
  const handleRemoveClick = (
    productId: string,
    productName: string,
    index: number,
    optionsServiceHeading?: any,
    packAmountOptions?: any
  ) => {
    const key = `${
      optionsServiceHeading ? optionsServiceHeading : serviceHeading
    }-${index}`;
    console.log('remove', key);
    setCounts((prevCounts) => {
      const currentData = prevCounts[key];
      if (!currentData) return prevCounts;

      const newCount = currentData.count - 1;
      if (newCount <= 0) {
        const { [key]: _, ...rest } = prevCounts;
        return rest;
      }
      return {
        ...prevCounts,
        [key]: {
          ...currentData,
          count: newCount,
        },
      };
    });
    updateTotalCounts(
      productName,
      -1,
      productData?.packAmount || 0,
      productData?.discountPrice || 0
    );
  };

  React.useEffect(() => {
    if (productData) {
      console.log('productData--1', productData);
      const matchingItem = totalCart.find(
        (item: TotalCount) => item.packName === productData.packName
      );
      console.log('matchingItem', matchingItem);
      if (matchingItem) {
        console.log('matchingItem--2', matchingItem);
        setCounts((prevCounts) => ({
          ...prevCounts,
          [`${serviceHeading}-${index}`]: {
            count: matchingItem.count,
            packAmount: matchingItem.packAmount,
            discountPrice: matchingItem.discountPrice,
          },
        }));
      } else {
        productData?.options ? (
          <></>
        ) : (
          setCounts((prevCounts) => {
            const { [`${serviceHeading}-${index}`]: _, ...rest } = prevCounts;
            return rest;
          })
        );
        console.log('elseeee--3', counts);
      }
    }
  }, [totalCart, productData, serviceHeading, index]);
  console.log('viewData-details', viewData);
  return (
    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} rowSpacing={1}>
      <Grid
        item
        container
        xl={11}
        lg={11}
        md={11}
        sm={11}
        xs={12}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          lg={8}
          md={6}
          sm={12}
          xl={8}
          container
          rowSpacing={1}
          columnSpacing={2}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className='service-amount '>
              {productData?.serviceAmount}
            </Typography>
            <Typography className='fw-700 f-16 os-bold lh-24'>
              {productData?.packName}
            </Typography>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
            <Avatar sx={{ bgcolor: '#0F8ECA', width: 24, height: 24 }}>
              <StarOutlineIcon />
            </Avatar>
          </Grid>
          <Grid item xl={11} lg={11} md={11} sm={11} xs={10}>
            <Typography className='text-dotted-underline fw-400 f-13'>
              {productData?.rating} ({productData?.booking} Booking)
            </Typography>
          </Grid>
          <Grid item xl={1.5} lg={1.5} md={1.5} sm={3} xs={3}>
            <Typography className='fw-700'>
              ₹{productData?.packAmount}
            </Typography>
          </Grid>
          {productData?.discountPrice && (
            <Grid item xl={2} lg={2} md={2} sm={3} xs={3}>
              <Typography className='font-line-through gray-font'>
                ₹{productData?.discountPrice}
              </Typography>
            </Grid>
          )}

          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Typography className='service-amount'>
              {productData?.time}
            </Typography>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <List>
              {productData?.description?.map(
                (desc: string, descIndex: number) => (
                  <ListItem key={descIndex} className='list-item-with-dot py-0'>
                    <ListItemText
                      primary={desc}
                      className='f-12 fw-400 font-OpenSans-Regular'
                    />
                  </ListItem>
                )
              )}
            </List>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              onClick={() => handleViewDetails(index, productData)}
              className='black-captital fw-600 f-14 blue-color'
            >
              View Details
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          lg={4}
          md={6}
          sm={8}
          xl={4}
          container
          className='image-container'
          justifyContent={'center'}
        >
          <img src={productData?.image} alt='Logo' className='product-image' />
          {productData?.options ? (
            <>
              {counts[`${serviceHeading}-${index}`] ? (
                <Grid container>
                  <Grid item xl={12}>
                    <Button
                      variant='outlined'
                      className='image-button'
                      size='small'
                      style={{
                        backgroundColor: 'white',
                        border: '2px solid rgb(110, 66, 229)',
                      }}
                    >
                      <Button
                        size='small'
                        onClick={() => handleViewDetails(index, productData)}
                      >
                        -
                      </Button>
                      <Typography sx={{ mx: 2 }}>
                        {productData?.options
                          ? Object.keys(counts).length
                          : counts[`${serviceHeading}-${index}`]?.count || 0}
                      </Typography>

                      <Button
                        size='small'
                        onClick={() => handleViewDetails(index, productData)}
                      >
                        +
                      </Button>
                    </Button>
                  </Grid>
                  <Grid item xl={12}>
                    {productData?.options && (
                      <Typography
                        textAlign={'center'}
                        className='f-14 fw-400 gray-font'
                      >
                        {productData?.options?.length} Options
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      variant='outlined'
                      style={{
                        backgroundColor: 'white',
                      }}
                      size={'small'}
                      className='blue-color f-14 fw-600 image-button-add'
                      onClick={() => handleViewDetails(index, productData)}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    {productData?.options && (
                      <Typography
                        textAlign={'center'}
                        className='f-14 fw-400 gray-font'
                      >
                        {productData?.options?.length} Options
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {counts[`${serviceHeading}-${index}`] ? (
                <CartButton
                  productData={productData}
                  index={index}
                  counts={counts}
                  serviceHeading={serviceHeading}
                  handleAddClick={handleAddClick}
                  handleRemoveClick={handleRemoveClick}
                  imageHeight={imageHeight}
                />
              ) : (
                // <Grid
                //   container
                //   item
                //   xl={12}
                //   lg={12}
                //   md={12}
                //   sm={12}
                //   xs={12}
                //   justifyContent={'center'}
                // >
                //   <Grid
                //     item
                //     xl={8}
                //     lg={8}
                //     md={8}
                //     sm={8}
                //     xs={12}
                //     container
                //     className='image-button px-10 py-5'
                //     style={{
                //       backgroundColor: 'white',
                //       border: '2px solid rgb(110, 66, 229)',
                //     }}
                //   >
                //     <Grid
                //       item
                //       xl={12}
                //       lg={12}
                //       md={12}
                //       sm={12}
                //       xs={12}
                //       container
                //       style={{
                //         display: 'flex',
                //         justifyContent: 'space-between',
                //       }}
                //     >
                //       <Grid item xl={4}>
                //         <Typography
                //           onClick={() =>
                //             handleRemoveClick(
                //               productData.id,
                //               productData.packName,
                //               index
                //             )
                //           }
                //           className='blue-button px-2 fw-700 f-14'
                //         >
                //           -
                //         </Typography>
                //       </Grid>
                //       <Grid item xl={4}>
                //         <Typography className='blue-button px-2 fw-700 f-14'>
                //           {counts[`${serviceHeading}-${index}`]?.count || 0}
                //         </Typography>
                //       </Grid>
                //       <Grid item xl={4}>
                //         <Typography
                //           onClick={() =>
                //             handleAddClick(
                //               productData.id,
                //               productData.packName,
                //               index
                //             )
                //           }
                //           className='blue-button px-2 fw-700 f-14'
                //         >
                //           +
                //         </Typography>
                //       </Grid>
                //     </Grid>
                //   </Grid>
                //   <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                //     {productData?.options && (
                //       <Typography
                //         textAlign={'center'}
                //         className='f-14 fw-400 gray-font'
                //       >
                //         {productData?.options?.length} Options
                //       </Typography>
                //     )}
                //   </Grid>
                // </Grid>
                <Grid container>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      variant='outlined'
                      style={{
                        backgroundColor: 'white',
                      }}
                      className={`blue-color f-14 fw-600 image-button-add ${
                        addHeight || ''
                      }`}
                      onClick={() =>
                        handleAddClick(
                          productData.id,
                          productData.packName,
                          index
                        )
                      }
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    {productData?.options && (
                      <Typography
                        textAlign={'center'}
                        className='f-14 fw-400 gray-font'
                      >
                        {productData?.options?.length} Options
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Divider variant='middle' sx={{ bgcolor: '#D3D3D3', mt: 2, mb: 2 }} />
      </Grid>

      <ViewDetails
        viewDetailsOpen={viewDetailsOpen}
        viewData={viewData}
        viewDetailsClose={handleViewDetailsClose}
        counts={counts}
        setCounts={setCounts}
        index={index}
        handleAddClick={handleAddClick}
        handleRemoveClick={handleRemoveClick}
        frequentlyAskedQuestion={frequentlyAskedQuestion}
        heading={heading}
        updateTotalCounts={updateTotalCounts}
        mainCarouselImages={mainCarouselImages}
      />
    </Grid>
  );
};

export default ProductDetails;
