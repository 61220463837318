import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import m2Water from '../../images/natve/waterPurifier.png';
import homeConvience from '../../images/natve/homeConvience.png';
import m1Water from '../../images/natve/m1Water.png';
import year from '../../images/natve/2year.png';
import bestClass from '../../images/natve/bestClass.png';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import warranty from '../../images/natve/warranty.png';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import './native.css';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
const NativeScreen: React.FC = () => {
  const { t } = useTranslation();
  const reviews = [
    {
      name: 'Shwetha Iyar',
      serviceName: 'For Classic Bathroom Cleaning',
      review:
        '10-Stage filterationat this price  is unheard of.Itsa  game changer',
      star: '5',
      date: 'Jan 14',
      place: 'Bangalore',
    },
    {
      name: 'Shwetha Iyar',
      serviceName: 'L1 pro smart lock',
      review: 'Native smart locks making unlocking the door easy for my mom',
      star: '5',
      date: 'Feb 14',
      place: 'Gurgon',
    },
    {
      name: 'Shreya Virmani',
      serviceName: 'M2 RO',
      review:
        'No more holding he water bottle till it fills up. the pre-sets are great',
      star: '5',
      date: 'Dec 22',
      place: 'Mumbai',
    },
  ];
  return (
    <Grid container className='justify-center mt-30'>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-20 py-20'
      >
        <img
          src={m2Water}
          alt='native'
          style={{ width: '100%' }}
          className='image-pointer'
        />
      </Grid>{' '}
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-20 py-20'
      >
        <img
          src={homeConvience}
          alt='native'
          style={{ width: '100%' }}
          className='image-pointer'
        />
      </Grid>{' '}
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-20 py-20'
      >
        <img
          src={m1Water}
          alt='native'
          style={{ width: '100%' }}
          className='image-pointer'
        />
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-20 py-20'
      >
        <img
          src={year}
          alt='native'
          style={{ width: '100%' }}
          className='image-pointer'
        />
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-10 py-20 justify-center'
        container
      >
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Typography className='fw-600 f-36 font-os-semi-bold'>
            {t('MOST_BOOKED_SERVICES')}
          </Typography>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <img
              src={warranty}
              alt='native'
              className='image-pointer'
              style={{ width: '100px' }}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <Typography>Unconditional Warranty</Typography>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <ReplayCircleFilledIcon sx={{ fontSize: '100px' }} />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <Typography>Free Replacement</Typography>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <ConstructionIcon sx={{ fontSize: '100px' }} />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className='justify-center'
          >
            <Typography>Free Delivery</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='py-20'
        container
        columnSpacing={1}
      >
        {reviews.map((review, index) => (
          <Grid
            item
            xl={3.8}
            lg={3.8}
            md={3.8}
            sm={12}
            xs={12}
            container
            className='py-20 footer-main mx-5 br-10 px-10'
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='justify-space-between'
              container
              rowGap={2}
            >
              <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
                <Typography className='fw-600 f-20'>{review.review}</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                {review?.star >= '4' ? (
                  <Button
                    color='success'
                    variant='contained'
                    startIcon={<StarIcon />}
                    className='f-16'
                    size='small'
                  >
                    {review?.star}
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    startIcon={<StarIcon />}
                    style={{ backgroundColor: 'rgb(219, 109, 36)' }}
                    className='f-16'
                    size='small'
                  >
                    {review?.star}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='mt-20'
            >
              <span className='fw-800 f-18'>{review?.name}</span>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography>
                <span className='f-14 fw-700 gray-font'>
                  {review?.serviceName}
                </span>
                <span className='px-5 f-14 fw-700 gray-font dot'>
                  {review?.date}
                </span>
                <span className='px-5 f-14 fw-700 gray-font dot'>
                  {review?.place}
                </span>
              </Typography>
            </Grid>{' '}
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='px-20 py-20'
      >
        <img src={bestClass} alt='native' style={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};

export default NativeScreen;
