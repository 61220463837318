import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, Grid, IconButton, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import './dashboard.css';
import i18next from 'i18next';
import CustomButton from '../../Components/CustomButton';
import { APP_ROUTES, AUTH_ROUTES } from '../../routes/constant';
import CustomRadioGroup from '../../Components/CustomRadioGroup';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface UserProps {
  languageOpen?: any;
  handleLanguageClose?: any;
  contentType?: 'LOGIN' | 'DASHBOARD';
  setLanguageOpen?: any;
}

const LanguageSelector: React.FC<UserProps> = ({
  languageOpen,
  handleLanguageClose,
  contentType = 'LOGIN',
  setLanguageOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const languageList = [
    { value: 'en', label: 'English' },
    { value: 'ta', label: 'தமிழ்' },
    ,
  ];
  const [selectedOption, setSelectedOption] = React.useState<any>(false);
  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };
  const changeLanguage = () => {
    i18next.changeLanguage(selectedOption);
    localStorage.setItem('ln', selectedOption);

    if (contentType === 'DASHBOARD') {
      console.log('contentType', contentType);
      navigate(APP_ROUTES.DASHBOARD);
    }
    setLanguageOpen(false);
  };
  React.useEffect(() => {
    if (contentType === 'LOGIN') {
      setSelectedOption('en');
    } else if (contentType === 'DASHBOARD') {
      setSelectedOption(localStorage.getItem('ln'));
    }
  }, []);
  return (
    <React.Fragment>
      <Dialog
        open={languageOpen}
        onClose={handleLanguageClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <IconButton
          aria-label='close'
          onClick={handleLanguageClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          {' '}
          <Typography variant='h5' component='h2'>
            {t('SELECT_LANGUAGE')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} className='mt-20 ml-20'>
              <Typography className='mb-10' fontSize={18} fontWeight={600}>
                Select Language
              </Typography>
            </Grid>
            <Grid item xs={12} className='mt-20 ml-20' height={250}>
              <CustomRadioGroup
                name='RadioGroup'
                options={languageList}
                value={selectedOption}
                onChange={handleOptionChange}
                size='small'
                inputClassName='my-4'
              />
            </Grid>
            <Grid
              container
              justifyContent='space-between'
              className='mt-30 ml-20'
            >
              <Grid className='py-5'>
                <Typography
                  className='mb-10 cursur-pointer'
                  fontSize={18}
                  onClick={() => setLanguageOpen(false)}
                >
                  {contentType === 'LOGIN' ? 'BACK' : 'CANCEL'}
                </Typography>
              </Grid>
              <Link
                to={
                  contentType === 'LOGIN'
                    ? AUTH_ROUTES.LOGIN
                    : APP_ROUTES.DASHBOARD
                }
              >
                <Grid className='py-5 mr-30'>
                  <CustomButton
                    text={contentType === 'LOGIN' ? t('CONTINUE') : 'CHANGE'}
                    variant='text'
                    color='primary'
                    fontSize={18}
                    fontWeight={600}
                    onClick={changeLanguage}
                    // onClick={() => setOpen(false)}
                  />
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions style={{ padding: '10px' }}>
          <Button
            variant='contained'
            size='large'
            fullWidth
            onClick={handleOtpClose}
            disabled={isButtonDisabled}
          >
            Proceed
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
};

export default LanguageSelector;
