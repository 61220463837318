import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/font.css';
import './generator.scss';

import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <Suspense>
    <App />
  </Suspense>,

  document.getElementById('root')
);

reportWebVitals();
