import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Alert,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import cartImage from '../../images/cart.png';
import discount from '../../images/discount.png';
import ucpromise from '../../images/home/ucpromise.png';
import { useTranslation } from 'react-i18next';
import DiscountIcon from '@mui/icons-material/Discount';
import DoneIcon from '@mui/icons-material/Done';
import './product.css';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import { useCart } from './CartProvider';
interface CartItem {
  packName: string;
  count: number;
  packAmount: number;
  discountPrice: number;
}

const AddtoCart: React.FC = () => {
  const { totalCart, setTotalCart } = useCart();
  const { t } = useTranslation();
  const [savedAmount, setSavedAmount] = useState<number | undefined>(undefined);
  const [totalPackAmount, setTotalPackAmount] = useState<number | undefined>(
    undefined
  );
  const [totalDiscountPrice, setTotalDiscountPrice] = useState<
    number | undefined
  >(undefined);
  const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined);
  const [expanded, setExpanded] = useState(false);

  const toggleOffers = () => {
    setExpanded(!expanded);
  };
  const handleAddClick = (packName: string) => {
    setTotalCart((prevTotalCart) =>
      prevTotalCart.map((item) =>
        item.packName === packName ? { ...item, count: item.count + 1 } : item
      )
    );
  };

  const handleRemoveClick = (packName: string) => {
    setTotalCart((prevTotalCart) =>
      prevTotalCart
        .map((item) =>
          item.packName === packName && item.count > 0
            ? { ...item, count: item.count - 1 }
            : item
        )
        .filter((item) => item.count > 0)
    );
  };
  console.log('addd-totalcart', totalCart);
  useEffect(() => {
    const totalSaved = totalCart.reduce((acc, item) => {
      return acc + (item.discountPrice - item.packAmount) * item.count;
    }, 0);
    setSavedAmount(totalSaved);

    const totalPackAmt = totalCart.reduce((acc, item) => {
      return acc + item.packAmount * item.count;
    }, 0);
    setTotalPackAmount(totalPackAmt);

    const totalDiscPrice = totalCart.reduce((acc, item) => {
      return acc + item.discountPrice * item.count;
    }, 0);
    setTotalDiscountPrice(totalDiscPrice);

    const total = totalCart.reduce((acc, item) => {
      return acc + item.packAmount * item.count;
    }, 0);
    setTotalPrice(total);
  }, [totalCart]);
  console.log('totalDiscountPrice', totalDiscountPrice);
  return (
    <Grid
      container
      item
      xl={12}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      rowGap={2}
      style={{ height: '700px', overflowY: 'auto' }}
    >
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='gray-border mt-30'
      >
        <h4 className='px-10 fw-8 font-os-semi-bold f-20'>Cart</h4>
        {totalCart.length === 0 ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            rowSpacing={2}
            className='p-10'
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className='justify-center'
            >
              <img src={cartImage} alt='cart' />
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography className='f-14 fw-400'>
                No items in your cart
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            className='justify-center p-10'
            rowSpacing={2}
          >
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              {totalCart.map((item, index) => (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  container
                  key={index}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                  }}
                  columnSpacing={2}
                >
                  <Grid
                    item
                    xl={6}
                    xs={12}
                    lg={6}
                    md={6}
                    sm={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>{item.packName}</Typography>
                  </Grid>
                  <Grid
                    item
                    xl={3.5}
                    lg={3.5}
                    md={3}
                    sm={6}
                    xs={6}
                    className='justify-space-between align-center'
                  >
                    <Button variant='outlined'>
                      <Button onClick={() => handleRemoveClick(item.packName)}>
                        -
                      </Button>
                      <Typography>{item.count}</Typography>
                      <Button onClick={() => handleAddClick(item.packName)}>
                        +
                      </Button>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={6}
                    xs={6}
                    container
                    className={'justify-center'}
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className='fw-7'>
                        ₹{item.packAmount}
                      </Typography>
                    </Grid>
                    {item.discountPrice > 0 && (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className='font-line-through'>
                          ₹{item.discountPrice}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} xl={12} lg={12} md={12} sm={12}>
              <Alert
                variant='filled'
                severity='success'
                className='justify-center'
                iconMapping={{
                  success: <DiscountIcon fontSize='inherit' />,
                }}
              >
                {savedAmount !== undefined && savedAmount > 0 ? (
                  <Typography className='fw-7'>
                    Congratulations! ₹ {savedAmount} saved so far
                  </Typography>
                ) : (
                  <Typography className='fw-7'>Congratulations!</Typography>
                )}
              </Alert>
            </Grid>
            <Grid item xs={10} xl={10} lg={10} md={10} sm={10}>
              <Alert
                variant='filled'
                severity='info'
                icon={false}
                action={
                  <Button
                    color='inherit'
                    size='small'
                    className='fw-600 f-14 white-captital'
                  >
                    View Cart
                  </Button>
                }
              >
                {totalDiscountPrice === 0 ? (
                  <Grid item xs={10} lg={10} md={10} sm={6} xl={6}>
                    <Typography className='f-16 fw-700 font-os-semi-bold'>
                      ₹{totalPrice}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item container columnSpacing={2}>
                    <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                      <Typography className='f-16 fw-700 font-os-semi-bold'>
                        ₹{totalPrice}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xl={6}
                      className='font-line-through'
                    >
                      <Typography>₹{totalDiscountPrice}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Alert>
            </Grid>
          </Grid>
        )}
      </Grid>{' '}
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        container
        className='gray-border'
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <img src={discount} style={{ width: '50%' }} />
              </ListItemIcon>
              <ListItemText>
                <Grid container rowSpacing={1}>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Typography className='fw-400 f-14 font-os-semi-bold'>
                      CRED cashback upto ₹500
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Typography className='fw-400 f-12'>
                      Cashback upto ₹500 via CRED
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItemButton>
          </ListItem>{' '}
          <ListItem
            disablePadding
            style={{ display: expanded ? 'flex' : 'none' }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img src={discount} style={{ width: '50%' }} />
              </ListItemIcon>
              <ListItemText>
                <Grid container rowSpacing={1}>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Typography className='fw-4 f-14 font-os-semi-bold'>
                      Amazon cashback upto ₹50
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <Typography className='fw-4 f-12'>
                      Amazon cashback upto ₹50
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Button onClick={toggleOffers}>
          {expanded ? 'View Less Offers' : 'View More Offers'}
        </Button>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        container
        className='gray-border'
      >
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Typography className='fw-600 f-20 p-10 px-20 font-os-semi-bold'>
            UC Promise
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8} sm={8} md={8} xl={8}>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Verified Professionals'
                  className='fw-400 f-14'
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Safe Chemicals'
                  className='fw-400 f-14'
                />
              </ListItemButton>
            </ListItem>{' '}
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Superior Stain Removal'
                  className='fw-400 f-14'
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          xl={4}
          lg={4}
          md={4}
          sm={4}
          className='justify-center'
        >
          <img
            src={ucpromise}
            style={{ width: '100px', height: '100px', marginTop: '10px' }}
            alt='promisee'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddtoCart;
