import React from 'react';
import Carousel, { CarouselProps } from 'react-multi-carousel';

interface ImageCarouselProps {
  images?: string[];
  responsive: CarouselProps['responsive'];
  arrows?: boolean;
  showDots?: boolean;
  infinite?: boolean;
  autoPlay?: boolean;
  draggable?: boolean;
  customChildren?: React.ReactNode;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images = [],
  responsive,
  arrows = true,
  showDots = false,
  infinite = false,
  autoPlay = false,
  draggable = false,
  customChildren = null,
}) => {
  return (
    <Carousel
      responsive={responsive}
      arrows={arrows}
      showDots={showDots}
      infinite={infinite}
      autoPlay={autoPlay}
      draggable={draggable}
    >
      {customChildren ||
        images.map((image, index) => (
          <div key={index} style={{ padding: '10px' }}>
            <img
              src={image}
              alt={`Carousel Item ${index + 1}`}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ))}
    </Carousel>
  );
};

export default ImageCarousel;
