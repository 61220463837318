export const APP_ROUTES = {
  DASHBOARD: '/',
  HOME: '/home',
  BEAUTY: '/beauty',
  NATIVE: '/native',
  CART: '/cart',
  BATHROOM: '/bathroom',
  AIR_COOLER_REPAIR: '/air-cooler',
  RATE_CARD: '/rate-card',
  AIR_PURIFIER: '/air-purifier',
};
export const AUTH_ROUTES = {
  LOGIN: '/login',
};
