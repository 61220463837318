import { Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from './constant';
import Dashboard from '../screens/Dashboard/Dasboard';
import HommeScreen from '../screens/Home/HomeScreen';
import BeautyScreen from '../screens/Beauty/BeautyScreen';
import NativeScreen from '../screens/Native/NativeScreen';
import CartScreen from '../screens/Cart/CartScreen';
import BathroomScreen from '../screens/Home/BathroomScreen';
import React from 'react';
import AirCoolerRepairServices from '../screens/Home/ApplianceRepairServices';
import AirPurifier from '../screens/Home/AirPurifier';
import RateCard from '../screens/Home/RateCard';

const AppRoutes = ({ isLogged }: any) => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />}>
        <Route path={APP_ROUTES?.HOME} element={<HommeScreen />} />
        <Route path={APP_ROUTES?.BEAUTY} element={<BeautyScreen />} />
        <Route path={APP_ROUTES?.NATIVE} element={<NativeScreen />} />
        <Route path={APP_ROUTES?.CART} element={<CartScreen />} />
        <Route path={APP_ROUTES?.BATHROOM} element={<BathroomScreen />} />
        <Route
          path={APP_ROUTES?.AIR_COOLER_REPAIR}
          element={<AirCoolerRepairServices />}
        />
        <Route path={APP_ROUTES?.AIR_PURIFIER} element={<AirPurifier />} />s
        <Route path={APP_ROUTES?.RATE_CARD} element={<RateCard />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
