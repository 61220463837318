import React from 'react';

import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/yaa-logi1.png';
import appStore from '../../images/appStore.png';
import playStore from '../../images/playStore.png';
import { APP_ROUTES } from '../../routes/constant';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import './dashboard.css';
const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      container
      className='py-20 px-30 footer-main'
    >
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className='px-10 py-30'
      >
        <img src={Logo} alt='footer' width={200} />
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className='px-10'>
        <List>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button className='footer-heading'>{t('COMPANY')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('ABOUT_US')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('TERMS_CONDIIONS')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>
              {t('ANTI_DESCRIMINATION_POLICY')}
            </Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('UC_IMPACT')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('CAREER')}</Button>
          </ListItem>
        </List>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className='px-10'>
        <List>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button className='footer-heading'>{t('FOR_CUSTOMERS')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('UC_REVIEWS')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('CATEGORIES_NEAR_YOU')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('BLOG')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>{t('CONTACT_US')}</Button>
          </ListItem>
        </List>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className='px-10'>
        <List>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button className='footer-heading'>{t('FOR_PARTNERS')}</Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Button className='footer-text'>
              {t('REGISTER_AS_A_PROFESSIONAL')}
            </Button>
          </ListItem>
        </List>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className='px-10'>
        <List>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button className='footer-heading'>
              {t('SOCAIL_MEDIA_LINKS')}
            </Button>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Grid container>
              <Grid item xl={2}>
                <Avatar>
                  <TwitterIcon />
                </Avatar>
              </Grid>
              <Grid item xl={2}>
                <Avatar>
                  <FacebookRoundedIcon />
                </Avatar>
              </Grid>
              <Grid item xl={2}>
                <Avatar>
                  <InstagramIcon />
                </Avatar>
              </Grid>
              <Grid item xl={2}>
                <Avatar>
                  <LinkedInIcon />
                </Avatar>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button>
              <img src={appStore} alt='' />
            </Button>
          </ListItem>
          <ListItem disableGutters className='py-5' disablePadding>
            <Button>
              <img src={playStore} alt='' />
            </Button>
          </ListItem>
        </List>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className='px-20 py-30'
      >
        <Divider sx={{ bgcolor: '#D3D3D3', mt: 2, mb: 2 }} className='px-30' />
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className='px-20 py-10 mt-10'
      >
        <Typography variant='subtitle2' className='px-10'>
          © Copyright 2024 Urban Company. All rights reserved. | CIN:
          U74140DL2014PTC274413
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
