import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableComponent from '../../Components/TableComponent';

const RateCard: React.FC = () => {
  const { t } = useTranslation();
  const servicingColumn = [
    { id: 'description', label: 'Description', minWidth: 170 },
    { id: 'charge', label: 'Service Charge', minWidth: 100 },
  ];

  const servicingData = [
    {
      description: 'Air cooler honeycomb pad (per sq. ft.)',
      charge: '₹149',
    },
    {
      description: 'Air cooler wood wool pad',
      charge: '₹219',
    },
    {
      description: 'Air cooler servicing & side pad replacement',
      charge: [
        <Typography variant='body2'>₹300</Typography>,
        <Typography
          variant='caption'
          color='textSecondary'
          textAlign={'center'}
        >
          (Labour ₹299)
        </Typography>,
      ],
    },
  ];
  const fanData = [
    {
      description: 'Regulator',
      charge: '₹299',
    },
    {
      description: 'Rotary Switch',
      charge: '₹119',
    },
    {
      description: 'Plug Top (5 Amp)t',
      charge: [
        <Typography variant='body2'>₹889</Typography>,
        <Typography
          variant='caption'
          color='textSecondary'
          textAlign={'center'}
        >
          (Labour ₹299)
        </Typography>,
      ],
    },
  ];
  const repairNoPowerData = [
    {
      description: 'Capacitor change',
      charge: [
        <Typography variant='body2'>₹299</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Motor (18 Inch) Re-Winding-Repair',
      charge: [
        <Typography variant='body2'>₹1119</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Motor (15 Inch) Re-Winding--Repair',
      charge: [
        <Typography variant='body2'>₹889</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Motor (18 Inch) New -Replacement(Copper)',
      charge: [
        <Typography variant='body2'>₹2499</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Motor (18 Inch) New -Replacement(Aluminum)',
      charge: [
        <Typography variant='body2'>₹1579</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Motor (15 Inch) New-Replacement(Aluminum)',
      charge: [
        <Typography variant='body2'>₹1349</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Kit motor Re-Winding-Repair',
      charge: [
        <Typography variant='body2'>₹889</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Kit Motor (New)-Replacement',
      charge: [
        <Typography variant='body2'>₹1699</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
  ];
  const waterPumpData = [
    {
      description: 'Water Pump (Upto 3 Feet)',
      charge: [
        <Typography variant='body2'>₹200</Typography>,
        <Typography
          variant='caption'
          color='textSecondary'
          textAlign={'center'}
        >
          (Labour ₹299)
        </Typography>,
      ],
    },
    {
      description: 'Water Pump (Upto 5 Feet)',
      charge: [
        <Typography variant='body2'>₹300</Typography>,
        <Typography
          variant='caption'
          color='textSecondary'
          textAlign={'center'}
        >
          (Labour ₹299)
        </Typography>,
      ],
    },
    {
      description: 'Swing Motor',
      charge: [
        <Typography variant='body2'>₹169</Typography>,
        <Typography
          variant='caption'
          color='textSecondary'
          textAlign={'center'}
        >
          (Labour ₹299)
        </Typography>,
      ],
    },
  ];
  const otherData = [
    {
      description: 'Cooler Pipe',
      charge: [
        <Typography variant='body2'>₹169</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Water Distributor',
      charge: [
        <Typography variant='body2'>₹89</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Air Cooler Pipe Elbow',
      charge: [
        <Typography variant='body2'>₹39</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Cooler Switches',
      charge: [
        <Typography variant='body2'>₹89</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Power cord with plug top',
      charge: [
        <Typography variant='body2'>₹169</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Motor Knob',
      charge: [
        <Typography variant='body2'>₹19</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Drain Plug Cooler',
      charge: [
        <Typography variant='body2'>₹9</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Cooler Leg (per pc)',
      charge: [
        <Typography variant='body2'>₹29</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Lead Wire (1MM -3 Core)',
      charge: [
        <Typography variant='body2'>₹89</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Cooler Panel (14*38)',
      charge: [
        <Typography variant='body2'>₹439</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Curtain (22*35) air cooler)',
      charge: [
        <Typography variant='body2'>₹189</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Wheel Replacement(4)',
      charge: [
        <Typography variant='body2'>₹399</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Blade (Plastic)',
      charge: [
        <Typography variant='body2'>₹229</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Fan Blade (Iron)',
      charge: [
        <Typography variant='body2'>₹349</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Motor fan leg set -4',
      charge: [
        <Typography variant='body2'>₹349</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Tower fan Blower',
      charge: [
        <Typography variant='body2'>₹689</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
    {
      description: 'Tank & Tray',
      charge: [
        <Typography variant='body2'>₹1829</Typography>,
        <Typography variant='caption' textAlign={'center'}>
          ₹299 (Labour )
        </Typography>,
      ],
    },
  ];
  return (
    <Grid container className='justify-center px-20 mt-50 py-20'>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        className='py-10 mt-50'
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{ backgroundColor: 'black' }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: 'black',
              }}
              className='px-10 f-18 fw-800'
            >
              Servicing
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent columns={servicingColumn} data={servicingData} />
          </AccordionDetails>
        </Accordion>
      </Grid>{' '}
      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className='py-10'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{ backgroundColor: 'black' }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: 'black',
              }}
              className='px-10 f-18 fw-800'
            >
              Repair No power
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent
              columns={servicingColumn}
              data={repairNoPowerData}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>{' '}
      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className='py-10'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{ backgroundColor: 'black' }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: 'black',
              }}
              className='px-10 f-18 fw-800'
            >
              Fan Not working/Slow fan speed(Speed issues)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent columns={servicingColumn} data={fanData} />
          </AccordionDetails>
        </Accordion>
      </Grid>{' '}
      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className='py-10'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{ backgroundColor: 'black' }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: 'black',
              }}
              className='px-10 f-18 fw-800'
            >
              Water Pump
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent columns={servicingColumn} data={waterPumpData} />
          </AccordionDetails>
        </Accordion>
      </Grid>{' '}
      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className='py-10'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            aria-controls='panel1-content'
            id='panel1-header'
            style={{ backgroundColor: 'black' }}
          >
            <Typography
              style={{
                color: 'white',
                backgroundColor: 'black',
              }}
              className='px-10 f-18 fw-800'
            >
              Other Repair Accessories
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent columns={servicingColumn} data={otherData} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default RateCard;
